import React, { Component } from "react";
import "../style.css";
import { ToastContainer, toast } from "react-toastify";
import AxiosApi from "../../../Api";
import Moment from "moment";
// import datePicker from "node_modules/react-bootstrap-date-picker";
import { CSVLink } from "react-csv";
// import ClipLoader from "react-spinners/ClipLoader";

export class Archive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            // loading:false,
            filesData: [],
            exportFlag: false,
        };
    }

    onChangeStartDate = (e) => {
        this.setState({
            startDate: e.target.value,
        });
    }
    onChangeEndDate = (e) => {
        this.setState({
            endDate: e.target.value,
        });
    }
    onSubmit = () => {

        if (this.state.startDate !== "" || this.state.endDate !== "") {
            AxiosApi
                .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getall", {
                    headers: {
                        userRole: this.state.userRole,
                        requesterEmail: this.state.approver,
                        startDate: Moment(this.state.startDate).format(
                            "DD-MM-YYYY"
                        ),
                        endDate: Moment(this.state.endDate).format(
                            "DD-MM-YYYY"
                        ),
                    },
                })
                .then((response) => {
                    this.setState({
                        filesData: response.data,
                        exportFlag: true,
                    });
                })
                .catch((error) => {
                    console.error("There was an error in fetching data !", error);
                    toast.error("There was an error in fetching data !");
                    this.setState({
                        exportFlag: false,
                    });
                });
        } else {
            toast.error("Incorrect Date Range selected");
            this.setState({
                exportFlag: false,
            })
        }
    }
    render() {
        return (
            <div className="archive">
                <div className="fresh-form">
                    <h4>Export Archival Data</h4>
                    {/* <h6 className="reminder">* Mandatory fields</h6> */}
                    <div className="userDetails">
                        <div className="fresh-field-wrapper">
                            <label className="fresh-label" for="fresh-request_zone">
                                <span className="fresh-title">
                                    <span className="reminder">* </span> Select Start Date&nbsp;
                                </span>
                            </label>
                            <input
                                id="fresh-new_value-user-profile"
                                className="fresh-input css-yk16xz-control"
                                onChange={this.onChangeStartDate}
                                type="date"
                                placeholder="DD-MM-YYYY"
                                onKeyDown="return false"
                                value={this.state.startDate}
                            ></input>
                        </div>

                        <div className="fresh-field-wrapper">
                            <label className="fresh-label" for="fresh-request_zone">
                                <span className="fresh-title">
                                    <span className="reminder">* </span> Select End Date&nbsp;
                                </span>
                            </label>
                            <input
                                id="fresh-new_value-user-profile"
                                className="fresh-input css-yk16xz-control"
                                onChange={this.onChangeEndDate}
                                type="date"
                                placeholder="DD-MM-YYYY"
                                onKeyDown="return false"
                                value={this.state.endDate}
                                max={Moment().format("YYYY-MM-DD")}
                            ></input>
                        </div>
                    </div>

                    <div>
                        {this.state.exportFlag ?
                            <div>
                                <span className="reminder">Your data is prepared. Click Export to download the file</span>
                                <CSVLink data={this.state.filesData}>
                                    <button
                                        className="fresh-button cust_btn-cls"
                                    >
                                        Export&nbsp;
                                    </button>
                                </CSVLink>
                            </div> :
                            <button
                                className="fresh-button cust_btn-cls"
                                onClick={this.onSubmit}
                            > Submit&nbsp;
                            </button>
                        }
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                ></ToastContainer>
            </div>
        );
    }
}

export default Archive;