import React, { Component, useState } from "react";
import "../style.css";
//import AxiosApi from "AxiosApi";
import AxiosApi from '../../../Api'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export class ResetPassword extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        
        return (
            <div>
                
            </div>
        )
    }
}
export default ResetPassword;