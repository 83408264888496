import React, { Fragment, useEffect, createRef, useState } from 'react';
import MdmNavbar from './MdmNavbar';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Row,
  Col,
  Card,
} from 'reactstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Alert } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPaginationArray } from '../../utils/pagination';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { Redirect } from "react-router-dom";
//import axios from 'axios';
//import axios from '../../Api'
import axios from 'axios'
import './styles.css';
import {roles,updateRoles} from '../../Constants'

const { SearchBar } = Search;
const moment = require('moment');
const selectOptions = {
  New: 'New',
  'On Hold': 'On Hold',
  'In Progress': 'In Progress',
  Closed: 'Closed',
  Resolved: 'Resolved',
};
const CreatedAtFormatter = (dataField, { CreatedDate }) => (
  <>
    <font className="font-8">
      {moment(CreatedDate).format('DD-MM-YYYY')}
      <br />
      {moment(CreatedDate).format('HH:mm')}
    </font>
  </>
);
const CreatedAtFormatter2 = (dataField, { SC_ExpectedResolutionDate__c }) => (
  <>
    <font className="font-8">
      {moment(SC_ExpectedResolutionDate__c).format('DD-MM-YYYY')}
      <br />
      {moment(SC_ExpectedResolutionDate__c).format('HH:mm')}
    </font>
  </>
);
const DisplayComment = ({ Id }) => {
  const [commentt, setCommentt] = useState('');
  function getC() {
    axios
      .get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getComments?parentId=${Id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
        },
      })
      .then((response) => {
        // console.log(response, 'response');
        setCommentt(
          response?.data?.records?.length > 0 ? response?.data?.records[0].CommentBody : '',
        );
      });
  }
  // console.log(commentt, 'comment');
  useEffect(() => {
    getC();
  }, []);
  return <p>{commentt ? commentt : null}</p>;
};
const CommentsFormatter = (dataField, { Id }) => <DisplayComment Id={Id} />;

const PopUp = ({ CaseNumber }) => {
  const [singleTicketData, setSingleTicketData] = useState([]);
  const [caseId, setCaseId] = useState();
  function dataFetch() {
    axios
      .get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/ticketData?casenumber=${CaseNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
        },
      })
      .then((response) => {
        setSingleTicketData(response?.data?.records[0]);
        setCaseId(response?.data?.records[0]?.Id);
        return axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getComments?parentId=${response?.data?.records[0]?.Id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
          },
        });
      })
      .then((response) => {
        // console.log(response, 'comemnts');
        setComments(response.data.records);
      });

    // setTicketData(response.data.recordsets[0])
  }

  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState();
  const [Comments, setComments] = useState([]);

  const [added, setAdded] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [base64format, setBase64Format] = useState([]);
  const [visible, setVisible] = useState(false);
  const data = {
    CommentBody: comment,
    ParentId: caseId,
  };
  const AddComments = (e) => {
    e.preventDefault();
    comment?.length > 0 &&
      axios
        .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addComments`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
          },
        })
        .then((response) => {
          // console.log(response);
          setAdded(response?.data?.success);


        });

    handleUpload();
  };
  const [temp, setTemp] = useState();
  const dataReject = {
    status: 'In progress',
    SpeakUp__c: temp,
  };
  const rejectResolution = () => {
    temp.length > 0 && axios
      .patch(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/reject?ticketId=${caseId}`, dataReject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
        },
      })
      .then((response) => {
        // console.log(response);
        setRejected(response?.data?.success);
      });
  };
  const toggle = () => {
    setModal(!modal);
    setAdded(false);
    setUploaded(false);
    setRejected(false);
    dataFetch();
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [Files, setFiles] = useState([]);
  let linkdata = {};
  let uploadData = {};
  let uploadId = '';
  let contentId = '';
  const handleUpload = () => {
    for (let i = 0; i < base64format.length; i++) {
      uploadData = {
        Title: caseId,
        PathOnClient: Files[i],
        ContentLocation: 'S',
        VersionData: base64format[i].substring(base64format[i].indexOf(',') + 1),
      };
      axios
        .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/postUpload`, uploadData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
          },
        })
        .then((response) => {
          uploadId = response?.data?.id;

          return axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getContentId?uploadId=${uploadId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
            },
          });
        })
        .then((response) => {
          contentId = response?.data?.records[0]?.ContentDocumentId;
          linkdata = {
            ContentDocumentId: contentId,
            LinkedEntityId: caseId,
            Visibility: 'AllUsers',
          };
          return axios
            .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/linkUpload', linkdata, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
              },
            })
            .then((response) => setVisible(response?.data?.success));
        });
    }
  };
  const handleSubmit = (files) => {
    // console.log(files);
    files.map(async (f) => {
      setFiles((Files) => [...Files, f.file.name]);
      const base64 = await convertBase64(f.file);
      setBase64Format((base64format) => [...base64format, base64]);
    });
    setUploaded(true);
  };

  useEffect(() => {

    setComment("")
  }, [added])
  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file);
  };
  const onDismiss = () => setAdded(false);
  const onDismiss2 = () => setRejected(false);

  const onDismiss3 = () => setVisible(false);
  const onDismiss4 = () => setUploaded(false);
  // console.log(temp,'testing comment');

  return (
    <>
      <button
        onClick={toggle}
        style={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          color: 'blue',
        }}
      >
        {CaseNumber}
      </button>
      <Modal isOpen={modal} toggle={toggle} style={{ width: '35vw' }}>
        <ModalHeader
          toggle={toggle}
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          Ticket Number : {CaseNumber}
        </ModalHeader>
        <ModalBody>
          <strong>
            <h5>Ticket Information</h5>
          </strong>

          <table className="ticketTable">
            <tr>
              <td style={{ width: '50%' }}>Ticket Id</td>
              <td>{singleTicketData?.Id}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{singleTicketData?.SC_MDM_Category__c}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{singleTicketData?.SC_MDM_Type__c}</td>
            </tr>
            <tr>
              <td> Subtype</td>
              <td>{singleTicketData?.SC_MDM_Subtype__c}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{singleTicketData?.Status}</td>
            </tr>
            <tr>
              <td>Subject</td>
              <td>{singleTicketData?.Subject}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{singleTicketData?.Description}</td>
            </tr>
          </table>
          <hr style={{ padding: 0, margin: '.5vw' }} />
          <table className="ticketTable">
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Comments</strong>
              </td>
              <td style={{ width: '50%' }}>
                {/* {singleTicketData.Incident_Resolution__c} */}
                <div style={{ maxHeight: '12vw', overflowY: 'scroll' }}>
                  {Comments.map((value) => (
                    <p>
                      <strong>
                        <font style={{ color: '#f1c40f' }}>
                          {value.CreatedBy.Name === 'Integration User MDM'
                            ? 'You'
                            : value.CreatedBy.Name}
                          ({moment(value.CreatedDate).format('DD/MM/YYYY')})
                        </font>{' '}
                      </strong>
                      :: {value.CommentBody}{' '}
                    </p>
                  ))}
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ padding: 0, margin: '.5vw' }} />
          <table className="ticketTable">
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Add Comment</strong>
              </td>
              <td style={{ width: '50%' }}>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setTemp(e.target.value);
                  }}
                  value={comment}

                />
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <strong>Add Attachments</strong>
              </td>
              <td style={{ paddingTop: '.5vw', width: '50%' }}>
                <div id="file-js-example" className="file has-name is-fullwidth">
                  <br />
                  <Dropzone onChangeStatus={handleChangeStatus} onSubmit={handleSubmit}
                    submitButtonContent="Upload" />
                </div>
              </td>
            </tr>
            {/* ):null} */}
          </table>
          <Alert color="success" isOpen={uploaded} toggle={onDismiss4} fade={true}>
            Uploads ready to submit
          </Alert>
          <Alert color="success" isOpen={added} toggle={onDismiss} fade={true}>
            Comment Added<div className="ellipsis">{temp}</div>
          </Alert>
          <Alert color="success" isOpen={visible} toggle={onDismiss3} fade={true}>
            Uploads successful
          </Alert>
          <Alert color="danger" isOpen={rejected} toggle={onDismiss2} fade={true}>
            Resolution Rejected
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={AddComments}>
            Submit
          </Button>

          {singleTicketData?.Status === 'Resolved' && temp?.length > 0 && added ? (
            <Button color="secondary" onClick={rejectResolution}>
              Reject Resolution
            </Button>
          ) : null}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const PopUpFormatter = (dataField, { CaseNumber }) => <PopUp CaseNumber={CaseNumber} />;
const columns = [
  {
    dataField: 'CaseNumber',
    text: 'Ticket Number',
    formatter: PopUpFormatter,
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // filter: textFilter({
    //   delay: 100,
    // }),
  },

  {
    dataField: 'Status',
    text: 'Status',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    filter: selectFilter({
      options: selectOptions,
    }),
  },
  {
    dataField: 'Subject',
    text: 'Subject',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
  },
  {
    dataField: 'SC_MDM_Type__c',
    text: 'Type',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // filter: textFilter({
    //   delay: 100,
    // }),
  },
  {
    dataField: 'SC_MDM_Subtype__c',
    text: 'SubType',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // filter: textFilter({
    //   delay: 100,
    // }),
  },
  {
    dataField: 'CreatedDate',
    formatter: CreatedAtFormatter,
    text: 'Date/Time Opened',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
  },
  {
    dataField: 'SC_ExpectedResolutionDate__c',
    formatter: CreatedAtFormatter2,
    text: 'Expected Resolution',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
  },
  {
    dataField: 'Id',
    formatter: CommentsFormatter,
    text: 'Comment',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
  },
];

const dataMap = (element) => ({
  ...element,
});

const TicketsPage = () => {
  // const search = window?.location?.search;
  // const initialParams = new URLSearchParams(search);
  // const email = initialParams.get('email');
  const [ticketData, setTicketData] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const isAuthenticated = useIsAuthenticated();
  let table = createRef();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState();
  const { accounts, instance } = useMsal();
  useEffect(() => {
    // window.location.reload();

    if (accounts?.length !== 0) {
      changeName(accounts[0].name);
      // isAuthenticated=true;
      changeEmail(accounts[0].username);
    } else {
      changeName('');
      changeEmail();
    }
  }, [accounts]);
  // console.log(email, 'email');
  useEffect(() => {
    ticketsFetch();
  }, [email]);

 const client_id =
    process.env.REACT_APP_MDM_CLIENT_ID;
  const client_secret =
    process.env.REACT_APP_MDM_CLIENT_SECRET;
  const username = process.env.REACT_APP_MDM_USERNAME;
  const password = process.env.REACT_APP_MDM_PASSWORD;
  const grant_type = "password";
  const config = {
    method: 'POST',
    url: `https://abiglobalsolutioncenter.my.salesforce.com//services/oauth2/token?grant_type=${grant_type}&username=${username}&client_secret=${client_secret}&password=${password}&client_id=${client_id}`,

    header: {
      'Access-Control-Allow-Origin': '*',
    },
  };
  function ticketsFetch() {
    // console.log(email);
    axios(config)
      .then((response) => {
        updateRoles({ztToken: response.data.access_token})
        localStorage.setItem('ztToken', response.data.access_token);
        return email?.length > 0 && axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getTickets?email=${email}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("zt-token")}`,
          },
        });
      })
      .then((response) => {
        // console.log(response, 'fvgwufvuwf');
        setTicketData(response?.data.records);
      });

    // setTicketData(response.data.recordsets[0])
  }

  // useEffect(() => {

  // }, []);

  const dataa = typeof ticketData === 'object' ? ticketData?.map(dataMap) : [];
  const options = {
    custom: true,
    sizePerPage: 10,
    totalSize: ticketData?.length,
  };
  // console.log(isAuthenticated,'isAuthenticated')
  // if (!isAuthenticated) {
  //   return <Redirect to={{ pathname: "/login" }} />;
  // }
  return (
    <div>
      <MdmNavbar />
      <div
        style={{
          backgroundColor: 'white',
          paddingLeft: '1vw',
          paddingRight: '1vw',
          marginLeft: '10vw',
          marginRight: '10vw',
          marginTop: '1vw',
        }}
      >
        <ToolkitProvider keyField="id" data={dataa} columns={columns} search>
          {(props) => (
            <div >
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1", marginLeft: 0 }}><strong></strong></div>
                <SearchBar
                  {...props.searchProps}
                  style={{
                    marginTop: '1vw',
                    width: '25vw',
                    flex: "1"
                  }}
                /></div>

              <BootstrapTable
                classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 fontt table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger text-400 border-top  border-200"
                headerClasses="bg-200 text-700 border-y font-weight-bold border-200"
                filter={filterFactory()}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default TicketsPage;
