import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import MultipleValueTextInput from "react-multivalue-text-input";
import "../style.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
} from "react-bootstrap-table2-filter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import "react-multivalue-text-input/build/styles/styles.scss";
import "bootstrap/dist/css/bootstrap.css";
import Select from "react-select";
import AxiosApi from "../../../Api";
import cellEditFactory from "react-bootstrap-table2-editor";

import ClipLoader from "react-spinners/ClipLoader";

export class Dashbord extends Component {
  constructor(props) {
    super(props);

    this.onChangeActionType = this.onChangeActionType.bind(this);
    this.onchangeRejectionReason = this.onchangeRejectionReason.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);

    this.state = {
      filesData: [],
      nonSelected: [],
      selected: [],
      selectedIds: [],
      isHidden: true,
      new_status: "",
      show_rejection_reason: "rejectionRes hide_data",
      show_Comments: "rejectionRes hide_data",
      rejection_reason: "",
      approver: this.props.email,
      userRole: this.props.userRole,
      loading: false,
      requestTypeList: "",
      zoneList: "",
      requestorTypeList: "",
      requestReasonList: "",
      showSalesOrderFilter: false,
      showReqIdFilter: false,
      sortOrder: 'desc'
    };
  }

  pullData() {
    this.setState({ loading: true });
    AxiosApi
      .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getall", {
        headers: {
          userRole: this.state.userRole,
          requesterEmail: this.state.approver,
        },
      })
      .then((response) => {
        console.log(response.data.length)
        this.setState({
          filesData: response.data,
        });
        this.handleUnSelect(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error in fetching data !", error);
      })
      .finally(() => {
        this.setState({
          loading: false, // stop spinner (in response/error)
        });
      });

    //   AxiosApi
    //     .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getRequestType")
    //     .then((res) => {
    //       console.log(res);
    //       var ids = res.data.map((r) => {
    //         return r.label;
    //       });
    //       // console.log(ids["Customer"]);
    //       this.setState({
    //         requestTypeList: ids,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    //   AxiosApi
    //     .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getZone")
    //     .then((res) => {
    //       console.log(res);
    //       var ids = res.data.map((r) => {
    //         return r.label;
    //       });
    //       this.setState({
    //         zoneList: ids,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    //   AxiosApi
    //     .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getRequesterType")
    //     .then((res) => {
    //       console.log(res);
    //       var ids = res.data.map((r) => {
    //         return r.label;
    //       });
    //       this.setState({
    //         requestorTypeList: ids,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    //   AxiosApi
    //     .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getRequestReason")
    //     .then((res) => {
    //       console.log(res);
    //       var ids = res.data.map((r) => {
    //         return r.label;
    //       });
    //       this.setState({
    //         requestReasonList: ids,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  }

  componentDidMount() {
    console.log(this.state.userRole);
    this.pullData();
  }

  onChangeActionType(e) {
    this.setState({
      new_status: e.value,
    });
    if (e.value === 3) {
      this.setState({
        show_rejection_reason: "rejectionRes",
      });
    } else {
      this.setState({
        show_rejection_reason: "rejectionRes hide_data",
        rejection_reason: "",
      });
    }

    if (e.value === 1) {
      this.setState({
        show_Comments: "rejectionRes",
      });
    } else {
      this.setState({
        show_Comments: "rejectionRes hide_data",
        rejection_reason: "",
      });
    }
  }

  onchangeRejectionReason(e) {
    console.log(e.target.value);
    this.setState({
      rejection_reason: e.target.value,
    });
  }

  onClickSubmit() {
    if (
      this.state.new_status === "" ||
      ((this.state.new_status === 3 || this.state.new_status === 1) &&
        this.state.rejection_reason === "")
    ) {
      toast.error("Please populate the mandatory fields");
    } else {
      this.updateRequestStatus();
    }
  }

  updateRequestStatus = () => {
    console.log(this.state.selected);
    if (this.state.new_status !== "") {
      console.log(this.state.approver);
      AxiosApi
        .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/update", {
          selected: this.state.selected.map(x => x.Request_id),
          new_status: this.state.new_status,
          rejection_reason: this.state.rejection_reason,
          approver: this.state.approver,
        })
        .then((res) => {
          // then print response status
          console.log(res);
          if (res.data === "Please select requests to process.") {
            toast.warning(res.data);
          } else {
            toast.success("Request status modified successfully.");
          }

          setTimeout(() => window.location.reload(), 1000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unknown Error in Modifying status");
          setTimeout(() => window.location.reload(), 1000);
        });
    } else {
      toast.error("Select the New Status from Dropdown");
    }
  };

  handleUnSelect = (data) => {
    var ids = data.map((r) => {
      if (r.request_status === "Completed" || r.request_status === "WIP"
        || ((this.state.userRole === 1 || this.state.userRole === 2) && r.requester_email !== this.state.approver))
        return r.Sl_no;
      else return "";
    });



    this.setState(() => ({
      nonSelected: ids,
    }));
  };

  handleOnSelect = (row, isSelect) => {
    const selectedIds = this.state.filesData.filter(x => x.Request_id === row.Request_id).map(x => x.Sl_no);
    if (isSelect) {
      const arr = this.state.filesData.filter(x => x.Request_id === row.Request_id)
        .map(x => { return { Sl_no: x.Sl_no, Request_id: x.Request_id } });
      this.setState(() => ({
        selected: [...this.state.selected, ...arr],
        selectedIds: [...this.state.selectedIds, ...selectedIds]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x.Request_id !== row.Request_id),
        selectedIds: this.state.selectedIds.filter(x => !selectedIds.includes(x))
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => {
      return { Sl_no: r.Sl_no, Request_id: r.Request_id }
    });
    if (isSelect) {
      this.setState(() => ({
        selected: ids,
        selectedIds: ids.map(x => x.Sl_no),
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedIds: [],
      }));
    }
  };

  addItemMultipleSO = (item, allItems) => {
    this.setState({ loading: true });
    AxiosApi
      .post(
        process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterMultiple/",
        { SO: allItems, type: 1 },
        {
          headers: {
            userRole: this.state.userRole,
            requesterEmail: this.state.approver,
          },
        }
      )
      .then((response) => {
        this.setState({ loading: false });
        this.setState({
          filesData: response.data,
        });
        this.handleUnSelect(response.data);
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error("Error in performing Advance Filter");
      });
  };

  addItemMultipleREQID = (item, allItems) => {
    AxiosApi
      .post(
        process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterMultiple/",
        { SO: allItems, type: 2 },
        {
          headers: {
            userRole: this.state.userRole,
            requesterEmail: this.state.approver,
          },
        }
      )
      .then((response) => {
        this.setState({
          filesData: response.data,
        });
        this.handleUnSelect(response.data);
      })
      .catch((error) => {
        toast.error("Error in performing Advance Filter");
      });
  };

  onChangeAdvanceSearch = (e) => {
    this.setState({
      showSalesOrderFilter: false,
      showReqIdFilter: false,
    });
    if (e.value === 1) {
      this.setState({
        showSalesOrderFilter: true,
      });
      // this.pullData();
    } else if (e.value === 2) {
      this.setState({
        showReqIdFilter: true,
      });
      // this.pullData();
    }
  };

  render() {

    function indication() {
      return <div style={{ color: 'grey' }}>No Data to Show</div>
    }
    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div style={{ fontSize: '15px' }} className="div_export">
          <button type="button" className="fresh-button" onClick={handleClick}>
            Export to CSV
          </button>
        </div>
      );
    };

    const selectRow = {
      mode: "checkbox",
      clickToEdit: true,
      selected: this.state.selectedIds,
      nonSelectable: this.state.nonSelected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      bgColor: "rgb(240, 190, 185)",
      selectionRenderer: ({ mode, ...rest }) => (
        <input type={mode} {...rest} />
      ),
      headerColumnStyle: {
        verticalAlign: 'middle'
      },
      selectColumnStyle: {
        verticalAlign: 'middle'
      }
    };

    const sizePerPageList = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: this.state.filesData.length,
      },
    ];

    const columns = [
      {
        dataField: "Sl_no",
        text: "Serial No.",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        sort: true,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        hidden: true,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "Request_id",
        text: "REQ NO.",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem", width: "50px" },
        }),
        editable: false,
        style: { "white-space": "nowrap", width: '50px', verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "creation_date",
        text: "Creation Date",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        sort: true,
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        onSort: (field, order) => {
          this.setState({
            field,
            sortOrder: order
          });
        },
        sortFunc: (a, b, order, dataField) => {
          const arrA = a.split('-');
          const arrB = b.split('-');
          const dateA = new Date(parseInt(arrA[2]), parseInt(arrA[1] - 1), parseInt(arrA[0]));
          const dateB = new Date(parseInt(arrB[2]), parseInt(arrB[1] - 1), parseInt(arrB[0]));
          if (order === 'desc') {
            if (dateA < dateB) {
              return 1;
            } else if (dateA > dateB) {
              return -1;
            } else {
              return 0;
            }
          } else if (order === 'asc') {
            if (dateA < dateB) {
              return -1;
            } else if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          }
        },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "updateion_date",
        text: "Updation Date",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "date_order_registered",
        text: "Document Date",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "request_zone",
        text: "Zone",
        editable: false,
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { minWidth: "150px", verticalAlign: "middle" };
        },
      },
      {
        dataField: "request_type",
        text: "Request Type",
        editable: false,
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { minWidth: "150px", verticalAlign: "middle" };
        },
        formatter: (cell, row) => {
          if (row.text_type) {
            return <span>{cell + '-' + row.text_type}</span>
          }
          else return <span>{cell}</span>;
        }
      },
      {
        dataField: "requestor_type",
        text: "Requestor Type",
        editable: false,
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { minWidth: "150px", verticalAlign: "middle" };
        },
      },
      {
        dataField: "request_reason",
        text: "Request Reason",
        editable: false,
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { minWidth: "150px", verticalAlign: "middle" };
        },
      },
      {
        dataField: "request_flow",
        text: "Request Flow",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sales_order",
        text: "Sales Order",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sales_org",
        text: "Sales Organization",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem", verticalAlign: "middle" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "ship_to_country",
        text: "Ship To Country",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sku_no",
        text: "Item No.",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "material_no",
        text: "Material",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "old_value",
        text: "Old Value",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "new_value",
        text: "New Value",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "new_shipping_point",
        text: "New Shipping Point",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "request_status",
        text: "Request Status",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "bot_status",
        text: "Bot Status",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "bot_comments",
        text: "Bot Comments",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "comments",
        text: "Comments/Reason for Rejection",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "requester_email",
        text: "Requester Email",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "additional_notification_to",
        text: "Additional Notification To",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "additional_information",
        text: "Additional Information",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "quotation_number",
        text: "Quotation",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "credit_block",
        text: "Credit Block",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "delivery_block",
        text: "Delivery Block",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "inco_terms",
        text: "Incoterms",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sold_to_num",
        text: "Sold-To Number",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "sold_to_name",
        text: "Sold-To Name",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "ot",
        text: "OT Owner",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "cx",
        text: "CX Owner",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "Reason_Rej",
        text: "Reason For Rejection",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "po_date",
        text: "Customer PO Date",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        editable: false,
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "cust_po_num",
        text: "Customer PO Number",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "delivery_no",
        text: "Delivery Number",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "shipment_no",
        text: "Shipment Number",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "order_type",
        text: "Sales Document Type",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
      {
        dataField: "approver",
        text: "Request Status Last Modified by",
        filter: textFilter({
          className: "filter_style",
          style: { fontSize: "0.75rem" },
        }),
        style: { "white-space": "nowrap", verticalAlign: "middle" },
        editable: false,
        headerStyle: () => {
          return { verticalAlign: "middle" };
        },
      },
    ];

    const actionSelect = [
      { label: "Pending Approval", value: 1 },
      { label: "Approve", value: 2 },
      { label: "Reject", value: 3 },
      { label: "OA to be sent", value: 5 },
      { label: "PO Date to be changed", value: 6 },
      { label: "Completed", value: 7 },
    ];
    const actionSelectRequestor = [{ label: "Reject", value: 3 }];

    const advanceSelectOptions = [
      { label: "Sales Order", value: 1 },
      { label: "REQ ID", value: 2 },
    ];
    return (
      <div className="Dashbored_div">
        <div className="div_approve_reject_wrapper ">
          <div style={{ marginRight: '40px' }}>
            <div className="div_approve_reject">
              <label className="fresh-label" for="fresh-action">
                <span style={{ fontSize: '16px' }} className="fresh-title">
                  <span className="reminder">* </span>Modify Selected :
                </span>
              </label>
              <Select
                options={
                  this.state.userRole == 3 || this.state.userRole == 4
                    ? actionSelect
                    : actionSelectRequestor
                }
                onChange={this.onChangeActionType}
              />
            </div>
            <div className={this.state.show_rejection_reason}>
              <label className="fresh-label" for="fresh-rejection-reason">
                <span className="fresh-title">
                  <span className="reminder">* </span> Rejection Reason&nbsp;
                </span>
              </label>
              <input
                style={{ cursor: 'text' }}
                className="fresh-input css-yk16xz-control"
                onChange={this.onchangeRejectionReason}
                type="text"
                value={this.state.rejection_reason}
              ></input>
            </div>
            <div className={this.state.show_Comments}>
              <label className="fresh-label" for="fresh-rejection-reason">
                <span className="fresh-title">
                  <span className="reminder">* </span> Comments&nbsp;
                </span>
              </label>
              <input
                style={{ cursor: 'text' }}
                className="fresh-input css-yk16xz-control"
                onChange={this.onchangeRejectionReason}
                type="text"
                value={this.state.rejection_reason}
              ></input>
            </div>
            <button
              type="button"
              className="submit dashboard-button"
              onClick={this.onClickSubmit}
            >
              Submit
            </button>
          </div>
          <div className="advanced-Search text-center">
            <div className="">
              <label className="fresh-label" for="fresh-advanced-search">
                <span style={{ fontSize: '18px' }} className="fresh-title">
                  Advanced Search{" "}
                  <span className="reminder-2">
                    (separate multiple entries with COMMA or ENTER)
                  </span>
                </span>
              </label>

              <Select
                options={advanceSelectOptions}
                onChange={this.onChangeAdvanceSearch}
              />
            </div>

            {this.state.showSalesOrderFilter && (
              <MultipleValueTextInput
                onItemAdded={this.addItemMultipleSO}
                onItemDeleted={this.addItemMultipleSO}
                label="Sales Order"
                name="item-input"
                placeholder="Enter sales order separate them with COMMA or ENTER."
                deleteButton={<span>[x]</span>}
              />
            )}

            {this.state.showReqIdFilter && (
              <MultipleValueTextInput
                onItemAdded={this.addItemMultipleREQID}
                onItemDeleted={this.addItemMultipleREQID}
                label="REQ ID"
                name="item-input"
                placeholder="Enter request number separate them with COMMA or ENTER."
                deleteButton={<span>[x]</span>}
              />
            )}
          </div>
        </div>
        <div className="container-body" style={{ margin: '10px' }}>
          <ToolkitProvider
            bootstrap4
            keyField="Sl_no"
            data={this.state.filesData}
            columns={columns}
            exportCSV={{
              fileName: "RequestList.csv",
              exportAll: false,
              onlyExportFiltered: true,
              onlyExportSelection: false,
            }}
          >
            {(props) => (
              <div>
                <MyExportCSV {...props.csvProps} />
                <BootstrapTable
                  noDataIndication={indication}
                  filter={filterFactory()}
                  filterPosition="top"
                  pagination={paginationFactory({
                    sizePerPage: 50,
                    sizePerPageList: sizePerPageList,
                  })}
                  selectRow={selectRow}
                  sort={{ dataField: "creation_date", order: this.state.sortOrder }}
                  headerClasses="cust_header-class header-sticky "
                  wrapperClasses="table-responsive margin10Bottom text-center table-scroll"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      var req = { newValue: newValue, req_no: row.Request_id };
                      console.log(req);
                      AxiosApi
                        .post(
                          process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/updateCell/update",
                          req
                        )
                        .then((res) => {
                          console.log(res);
                          toast.success(
                            "Updated Additional Notification for " +
                            row.Request_id
                          );
                        })
                        .catch((err) => {
                          console.log(err);
                          toast.error(
                            "Error in updating Additional Notification"
                          );
                        });
                      this.pullData();
                    },
                  })}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
        ></ToastContainer>
        {<div className='text-center'><ClipLoader loading={this.state.loading} /></div>}
      </div>
    );
  }
}

export default Dashbord;
