import axios from "axios";
import React, { useState } from "react";
import ConfirmAlert from "./common/ConfirmAlert";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const NewPassword = () => {
  const [email, setEmail] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [oldPasswordError, setOldPasswordError] = useState(2);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(2);
  const [successData, setSuccessData] = useState();
  const [passwordError, setPasswordError] = useState();
  const [userExist, setUserExist] = useState(2);
  var saltKey;

  const checkOldPassword = (value) => {
    if (value.length >= 6) {//quering login master table for username and password
      axios.get(`/fetchSaltKey?email=${email}`)
        .then((response) => {
          if (response.data.recordset[0]?.saltKey.length > 0) {
            saltKey = response.data.recordset[0]?.saltKey;
            setUserExist(1)
          }
          else setUserExist(0);
          console.log(response.data)
          return axios.get(`/checkRecord?email=${email}&password=${value}&saltKey=${response.data.recordset[0]?.saltKey}`)
        })
        .then((response) => {
          console.log(response)
          if (response?.data?.recordsets[0].length == 1)
            setOldPasswordError(0)
          else
            setOldPasswordError(1) //if response has 1 record setOldPasswordError(false)else true;
        })
    }
  }
  const checkConfirmPassword = (value) => {
    if (value == newPassword)
      setConfirmNewPasswordError(0);
    else
      setConfirmNewPasswordError(1);
  }
  const onSubmit = (e) => {
    e.preventDefault();

    axios.put(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/updateLoginMaster`, { email: email, password: newPassword })
      .then((response) => {
        console.log(response); setSuccessData(response?.data?.response);
        toast.success('Password sent to your mail.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
        });
        window.location.reload();
      })
  }

  async function hideAlert() {
    window.location.reload();
  }
  const isValidPassword = (value) => {
    var passw = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}[^']$/;
    if (value.match(passw))
      setPasswordError('Valid Password')
    else
      setPasswordError('Invalid Password')

  }
  return (
    <>
      <div className="container-fluid px-1 py-5 mx-auto">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
            <h3>New Password</h3>

            <div className="card" id="newcard" style={{ padding: "30px 40px", borderRadius: "5px" }}>

              <form className="form-card" onsubmit="event.preventDefault()">
                <div className="row justify-content-between text-left">
                  <div className="form-group col-sm-6 flex-column d-flex">
                    {" "}
                    <label className="form-control-label px-3">
                      Email

                      <span className="text-danger"> *</span>
                      {userExist === 0 ? <font style={{ fontSize: "12px", color: 'red ' }}>Your Email is not registered</font> : null}
                    </label>
                    <input
                    className='form-control'
                      type="text"
                      id="email"
                      name="email"
                      placeholder=""
                      // onblur="validate(3)"
                      onChange={(e) => { setEmail(e.target.value); }}
                    />{" "}
                  </div>
                  <div className="form-group col-sm-6 flex-column d-flex">
                    <label className="form-control-label px-3">
                      Email Generated Password<span className="text-danger"> *</span>

                      {oldPasswordError === 1 && userExist === 1 ? <font style={{ fontSize: "12px", color: 'red ' }}>Username and Password donot match</font> : null}
                      {oldPasswordError === 0 ? <font style={{ fontSize: "12px", color: 'green' }}>Correct Username and Password</font> : null}
                    </label>{" "}
                    <input type="password" id="mob" name="mob" className='form-control' placeholder="" onChange={(e) => { setOldPassword(e.target.value); checkOldPassword(e.target.value) }} />{" "}
                  </div>
                </div>
                <div className="row justify-content-between text-left">
                  <div className="form-group col-sm-6 flex-column d-flex">
                    {" "}
                    <label className="form-control-label px-3">
                      New Password
                      <span className="text-danger"> *</span>
                      {passwordError === 'Invalid Password' ? <font style={{ fontSize: "12px", color: 'red ' }}>{passwordError}</font> : null}
                      {passwordError === 'Valid Password' ? <font style={{ fontSize: "12px", color: 'green' }}>{passwordError}</font> : null}
                    </label>
                    <input
                      type="password"
                      className='form-control'
                      id="email"
                      name="email"
                      placeholder=""
                      onChange={(e) => { setNewPassword(e.target.value); isValidPassword(e.target.value) }}
                    />
                    <font style={{ fontSize: "12px", color: 'red ' }}>The password must include upper case, lower case, symbols </font>
                    <font style={{ fontSize: "12px", color: 'red ' }}>The minimum length should be 6. Single Quote ' is not allowed.</font>
                  </div>
                  <div className="form-group col-sm-6 flex-column d-flex">
                    <label className="form-control-label px-3">
                      Confirm Password<span className="text-danger"> *</span>
                      {confirmNewPasswordError === 1 ? <font style={{ fontSize: "12px", color: 'red ' }}>Password donot match</font> : null}
                      {confirmNewPasswordError === 0 ? <font style={{ fontSize: "12px", color: 'green' }}>Password matched!</font> : null}
                    </label>{" "}
                    <input type="password" id="mob" name="mob" placeholder="" className='form-control' onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                      checkConfirmPassword(e.target.value)
                    }} />{" "}
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="form-group col-sm-6">
                    {" "}
                    <button type="submit" className="btn-block btn-primary" onClick={onSubmit} disabled={passwordError == 'Invalid Password' || confirmNewPasswordError == 1}>
                      Change Password
                    </button>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewPassword;
