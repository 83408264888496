import React, { useState, Component, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotAuth from "./NotAuth.js";
//import { MantineProvider } from '@mantine/core';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Login, Home, Footer } from "./components";
import Contactus from "./components/Contactus";
import MdmTicketingHome from './components/mdm-ticketing/MdmTicketingHome'
// import { RoleContextProvider } from "../context/RoleContext";
// import { FormPageContextProvider } from "../context/formPageContext";
import Tickets from './components/mdm-ticketing/Tickets'
//import AxiosApi from 'AxiosApi';
import AxiosApi from './Api.js'
import NavBar from "./components/Navbar";
import FooterComponent from "./components/common/FooterComponent";



//retpack
import RequestFormRetpack from "./components/retPack/Components/requestform";
import ChangePassword from './components/retPack/Components/changepassword.jsx';
import DashbordRetpack from './components/retPack/Components/dashbord.jsx';
import ReportDashboard from './components/retPack/Components/reportDashboard.jsx';
import Resetpassword from './components/retPack/Components/resetpassword.jsx';
import Userregistration from './components/retPack/Components/userregistration.jsx'
import Navigation from './components/retPack/Components/Navigation.jsx'
// import NavBarR from './retPack/Components/navbar.jsx'
// import NavBar from "./Navbar";
//retpack

// OOC 
import "./components/ooc/App.css";
import RequestForm from "./components/ooc/oocComponent/reqForm";
import Dashbord from "./components/ooc/oocComponent/dashbord";
import Navbar_OOC from "./components/ooc/oocComponent/navbar";
import Uploader from "./components/ooc/oocComponent/fileUpload";
import Admin from "./components/ooc/oocComponent/createAdmin";
import Archives from "./components/ooc/oocComponent/Archive";
// import Footer from "./ooc/oocComponent/footer";
import UploadPage from "./components/ooc/oocComponent/uploadPage";
// end of OOC

//CQPortalComponents
import HomeComponent from './components/cq-portal/HomeComponent'
import AddComplaint2 from "./components/cq-portal/AddCommplaint2";
import ComplaintPag2 from './components/cq-portal/ComplaintPag2';// needs to be uncomment
import WizardExample from './components/cq-portal/ComplaintPage3.js';
import Allcomplaints from './components/cq-portal/All Complaints/Complaints.js';
// import Currencyconverter from "./cq-portal/currencyconverter";
// import Table3 from "./cq-portal/table3"
// import Followup from "./cq-portal/Followup";
// import StarComponent from "./cq-portal/StarComponent"
import Signup from './components/rbac/Signup';// ANAS
import NewPassword from './components/rbac/NewPassword';
import ForgotPassword from './components/rbac/forgotPassword';//ANAS
import Header from './components/rbac/common/Header';
import NotificationsTable from './components/cq-portal/NotificationsTable';
import { MantineProvider } from "@mantine/core";
import "./assets/styles/App.css";
import {roles,updateRoles} from './Constants.js'
import { loginRequest, snowBackendRequest } from './utils/authConfig.js';

const Pages = () => {
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const { accounts, instance } = useMsal();
  
  const [isLoggedInViaInput, setisLoggedInViaInput] = useState(false);
  const changeLoggedInViaInput = (bool) => {
    setisLoggedInViaInput(bool);
  };
  const [username, setusername] = useState();
  const [password, setpassword] = useState();

  const [state, setState] = useState({
    ooc_user_role: roles.ooc_user_role,
    email: roles.username,
    username: roles.name,
    isReady: false,
  });
 

  const pullUserRole = () => {
    AxiosApi
      .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getUserRole", { email: state.email })
      .then((res) => {
        console.log(res);
        setState((prevState) => ({
          ...prevState,
          userRole: res.data[0].user_role,
        }));
        // props.setRole(res.data[0].user_role);
      })
      .catch((err) => {
        console.log("User does not exist in the Role table. User role: Requestor");
      });
  };
  useEffect(() => {
    if (accounts.length > 0) {
       console.log('starting below');
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          // console.log(response);
          //test
          updateRoles({username: accounts[0].username})
          updateRoles({emailid: accounts[0].username})
          updateRoles({Authorization: response.idToken})
          console.log("hi 78829892")
          console.log(roles)
          localStorage.setItem('Authorization', response.idToken)
      
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
      
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/togetuserrole", { emailid: (accounts[0].username) }).then((res)=>{
        console.log(res)
        if (res?.data?.length) {
          updateRoles({userRole: res.data[0].label})
          updateRoles({isReady:true})
        }
        console.log(roles.userRole)
          const isValidUserRole = [3].includes(roles.userRole);
          console.log(isValidUserRole)
      })
      
  
      AxiosApi
        .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getUserRole", { email: accounts[0].username })
        .then((res) => {
          console.log(res)
          updateRoles({ooc_user_role:res.data[0].user_role})
          
          setState((prevState) => ({
            ...prevState,
            userRole: 3,
            isReady: true,
          }));
        })
        .catch((err) => {
          console.log("User does not exist in the Role table. User role: Requestor");
          setState((prevState) => ({
            ...prevState,
            isReady: true,
          }));
        });
    }
      }, [accounts]);
  // useEffect(() => {
  //   // pullUserRole();
  //   if (state.email) {
  //     AxiosApi.defaults.headers.common['User'] = state.email;
  //   } else {
  //     AxiosApi.defaults.headers.common['User'] = null;
  //     // If setting null does not remove `Authorization` header, try delete AxiosApi.defaults.headers.common['Authorization'];
  //   }
  //   AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/togetuserrole", { emailid: (roles.username) }).then((res)=>{
  //     console.log(res)
  //     if (res?.data?.length) {
  //       updateRoles({userRole: res.data[0].label})
  //     }
  //   })
    

  //   AxiosApi
  //     .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getUserRole", { email: state.email })
  //     .then((res) => {
  //       console.log(roles.userRole)
  //       const isValidUserRole = ["3"].includes(roles.userRole);
  //       console.log(isValidUserRole)
  //       setState((prevState) => ({
  //         ...prevState,
  //         userRole: 3,
  //         isReady: true,
  //       }));
  //     })
  //     .catch((err) => {
  //       console.log("User does not exist in the Role table. User role: Requestor");
  //       setState((prevState) => ({
  //         ...prevState,
  //         isReady: true,
  //       }));
  //     });
  //     console.log('Type of userRole:', typeof roles.userRole,roles.userRole);
  // }, [state.email]);
  //OOC code ends here

  return (
    <BrowserRouter>
      <Routes>

      <Route path="/forgotPassword" element={   <React.Fragment>
              <Header />
              <ForgotPassword />
            </React.Fragment>} />

         <Route
          path="/newPassword"
          element={<React.Fragment>
            <Header />
            <NewPassword />
          </React.Fragment>}
        />  

        <Route
          path="/"
          element={<Home loggedInViaInput={isLoggedInViaInput} />}
        />
        <Route
          path="/register"
          element={
            <React.Fragment>
              <Header />
              <Signup />
            </React.Fragment>
          }
        />

        <Route
          path="/myTickets"
          element={
            roles.username?.length > 0 ? (
              <Tickets />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/contactus"
          element={
            <React.Fragment>
              <NavBar />
              <Contactus />
            </React.Fragment>
          }
        />

        <Route
          path="/mdm"
          element={
            roles.username?.length > 0 ? (
              <MdmTicketingHome />
              // <h1>Work is on Progress. Sorry for inconvenience.</h1>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/login"
          element={
            <Login
              username={username}
              setusername={setusername}
              password={password}
              setpassword={setpassword}
              loggedInViaInputFn={changeLoggedInViaInput}
              loggedInViaInput={isLoggedInViaInput}
              setisLoggedInViaInput={setisLoggedInViaInput}
            />
          }
        />
        {/*CQ Portal routes*/}
        <Route
          path="/cq-portal-home"

          element={
            roles.username?.length > 0 && roles.role === 'Employee' ? (
              <h1>You are not authorized to view this</h1>
            ) : roles.username?.length > 0 ? (
              <HomeComponent />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/complaints"
          element={
            roles.username ?.length > 0 && roles.role === 'Employee' ? (
              <h1>You are not authorized to view this</h1>
            ) : roles.username?.length > 0 ? (
              <Allcomplaints />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/add-complaint"
          element={
            (roles.username?.length) > 0 && roles.role === 'Manager' || roles.role === 'Employee' || (roles.role === 'ABI_CQ_SPOC') ? (
              <h1>You are not authorized to view this</h1>
            ) : roles.username?.length > 0 ? (
              <AddComplaint2 />
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        <Route
          path="/complaint/num/:ackno"
          // element={<h1>HI bro</h1>}
          element={
            (localStorage.getItem('Authorization'))?.length > 0 ? (
              (roles.role) === 'External_Customer' || (roles.role) === 'Internal_Customer' || (roles.role) === 'Employee' ? (
                <h1>You are not authorized to view this page</h1>
              ) : (
                <ComplaintPag2 />
                // <h1>Work is on Progress. Sorry for inconvenience.</h1>
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/complaint-2/num/:ackno"
          element={
            (roles.username)?.length > 0 ? (
              (roles.role) === 'ABI_CQ_SPOC' || (roles.role) === 'ABI_CQTeam' || (roles.role) === 'Employee' ? (
                <h1>You are not authorized to view this page</h1>
              ) : (
                <WizardExample />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/notificationspage"
          element={
            (roles.username)?.length > 0 ? (
              <NotificationsTable />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/one-order-change-homepage"
          element={
            (roles.username)?.length > 0 ? (
              (roles.ooc_user_role == 1 || roles.ooc_user_role == 2 || roles.ooc_user_role == 3 || roles.ooc_user_role == 4) ? (

                <React.Fragment>
                  {console.log("inside", (roles.ooc_user_role))}
                  <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                  <RequestForm email={state.email} />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/upload2"
          element={            (roles.username)?.length > 0 ? (
              (roles.ooc_user_role == 1 || roles.ooc_user_role == 2 || roles.ooc_user_role == 3 || roles.ooc_user_role == 4 ) ? (
                <React.Fragment>
                  <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                  <UploadPage email={roles.username} />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        <Route
          path="/dashboard"
          element={
            (roles.username)?.length > 0 ? (
              (roles.ooc_user_role == 1  || roles.ooc_user_role == 2  || roles.ooc_user_role == 3 || roles.ooc_user_role == 4) ? (
                <React.Fragment>
                  <Navbar_OOC name={roles.username} userRole={roles.ooc_user_role} email={roles.username} />
                  <Dashbord email={roles.username} userRole={roles.ooc_user_role} />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/upload"
          element={
            (roles.username)?.length > 0 ? (
              (roles.ooc_user_role == 1  || roles.ooc_user_role == 2  || roles.ooc_user_role == 3 || roles.ooc_user_role == 4) ? (
                <React.Fragment>
                  <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                  <Uploader email={roles.username} />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        <Route
          path="/userMgmt"
          element={
            (roles.username)?.length > 0 ? (
              (roles.ooc_user_role = 2 || roles.ooc_user_role == 3) ? (
                <React.Fragment>
                  <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                  {state.ooc_user_role == 2 || state.ooc_user_role == 3 ?
                    <Admin /> : <RequestForm email={roles.username}
                    />}
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/archive"
          element={
            roles.username?.length > 0 ? (
              (roles.ooc_user_role == 2 || roles.ooc_user_role == 3) ? (
                <React.Fragment>
                  <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                  {state.ooc_user_role == 2 || state.ooc_user_role == 3 ? <Archives /> : <RequestForm email={roles.username} />}
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        {/* retpack */}
        <Route
          path="/retPack"
          element={
            (roles.username)?.length > 0 ? (
              [1, 2, 3, 4].includes(roles.userRole)  ? (
                <React.Fragment>
                  <NavBar />
                  <Navigation />
                  <RequestFormRetpack
                    userRole={(roles.userRole)}
                    emailid={(roles.username)}
                    isAuthenticate={(roles.isAuthenticate)}
                    isReady={(roles.isReady)}
                  />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        <Route
          path="/retPack/requestform"
          element={
            (roles.username)?.length > 0 ? (
              [1, 2, 3, 4].includes(roles.userRole) ? (
                <React.Fragment>
                  <NavBar />
                  <Navigation />
                  <RequestFormRetpack
                    userRole={(roles.userRole)}
                    emailid={(roles.username)}
                    isAuthenticate={(roles.isAuthenticate)}
                    isReady={(roles.isReady)}
                  />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        <Route
          path="/retPack/reports"
          element={
            (roles.username)?.length > 0 ? (
              [1, 2, 3, 4].includes(roles.userRole) ? (
                <React.Fragment>
                  <NavBar />
                  <Navigation />
                  <ReportDashboard
                    userRole={roles.userRole}
                    emailid={roles.username}
                    isAuthenticate={roles.isAuthenticate}
                    isReady={roles.isReady}
                  />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />


        {/* <Route  path="/ForgotPassword" component={ForgotPassword} /> */}

        <Route path="/Registration"
          element={

            (roles.username)?.length > 0 ? (
              [1, 3].includes(roles.userRole) ? (
                <React.Fragment>
                  <NavBar />
                  <Navigation />
                  <Userregistration />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* <Route  path="/RestPassword" component={Resetpassword} /> */}

        <Route
          path="/retPack/Dashboard"
          element={
            (roles.username)?.length > 0 ? (
              [1,3].includes(roles.userRole) ? (
                <React.Fragment>
                  <NavBar />
                  <Navigation />
                  <DashbordRetpack />
                </React.Fragment>
              ) : (
                <NotAuth />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        {/* ooc routes */}
        {/* <Route
          path="/one-order-change-homepage"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                <RequestForm email={state.email} />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/upload2"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                <UploadPage email={state.email} />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/dashboard"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                <Dashbord email={state.email} userRole={state.ooc_user_role} />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/upload"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                <Uploader email={state.email} />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}

        {/* <Route
          path="/userMgmt"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                {state.ooc_user_role === 2 || state.ooc_user_role === 3 ?
                  <Admin /> : <RequestForm email={state.email}
                  />}
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}

        {/* <Route path="/archive"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                {state.ooc_user_role === 2 || state.ooc_user_role === 3 ? <Archives /> : <RequestForm email={state.email} />}
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
        {/* <Route
          path="/userMgmt"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                {state.ooc_user_role == 2 || state.ooc_user_role == 3 ?
                  <Admin /> : <RequestForm email={state.email}
                  />}
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/archive"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <Navbar_OOC name={state.username} userRole={state.ooc_user_role} email={state.email} />
                {state.ooc_user_role == 2 || state.ooc_user_role == 3 ? <Archives /> : <RequestForm email={state.email} />}
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}



        {/* retpack */}
        {/* <Route path="/retPack"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <NavBar />
                <Navigation />
                <RequestFormRetpack
                  userRole={localStorage.getItem("userRole")}
                  emailid={localStorage.getItem("username")}
                  isAuthenticate={localStorage.getItem("isAuthenticate")}
                  isReady={localStorage.getItem("isReady")}
                />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          } />


        <Route path="/retPack/requestform"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <NavBar />
                <Navigation />
                <RequestFormRetpack
                  userRole={localStorage.getItem("userRole")}
                  emailid={localStorage.getItem("username")}
                  isAuthenticate={localStorage.getItem("isAuthenticate")}
                  isReady={localStorage.getItem("isReady")}
                />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route path="/retPack/reports"
          element={
            localStorage.getItem("username")?.length > 0 ? (
              <React.Fragment>
                <NavBar />
                <Navigation />
                <ReportDashboard
                  userRole={localStorage.getItem("userRole")}
                  emailid={localStorage.getItem("username")}
                  isAuthenticate={localStorage.getItem("isAuthenticate")}
                  isReady={localStorage.getItem("isReady")}
                />
              </React.Fragment>
            ) : (
              <Navigate to="/login" />
            )}
        /> */}

        {/* <Route  path="/ForgotPassword" component={ForgotPassword} /> */}

        {/* <Route path="/Registration"
          element={
            <React.Fragment>
              <NavBar />
              <Navigation />
              <Userregistration />
            </React.Fragment>
          }
        /> */}

        {/* <Route  path="/RestPassword" component={Resetpassword} /> */}

        {/* <Route path="/retPack/Dashboard"
          element={
            <React.Fragment>
              <NavBar />
              <Navigation />
              <DashbordRetpack />
            </React.Fragment>
          }
        />

        <Route path="/ChangePassword" component={ChangePassword} />

        <Route path="/retpack/404"
          element={
            <React.Fragment>
              <NavBar />
              <h1>404 NOT AUTHORIZED</h1>
            </React.Fragment>
          }
        /> */}

        {/* retpack ends here */}

        <Route path="*" element={<h1>Error 404 Page Not Found</h1>} />

      </Routes>
    </BrowserRouter>
  );
};


function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (

    <div className="App">
     <MantineProvider > 
        <MsalProvider instance={msalInstance}>
          {navigator.onLine && <Pages />}
          {/* {!navigator.onLine && <NoInternet />} */}
        </MsalProvider>
       </MantineProvider> 
    </div>

  );
}

export default App;
