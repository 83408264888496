import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosApi from "../../../Api";
import Select from "react-select";
import Moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import LoadingOverlay from "react-loading-overlay";

import FileUpload from "./fileUpload";
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import "../style.css";

export class ReqForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isFormSubmitted: false,
			showPopup: false,
			showNewShippingPoint: "fresh-field-wrapper hide_data",
			showOldValue: "fresh-field-wrapper hide_data",
			showNewValue: "fresh-field-wrapper hide_data",
			showPartnerFunction: "fresh-field-wrapper hide_data",
			showTextTypes: "fresh-field-wrapper hide_data",
			showTextActionTypes: "fresh-field-wrapper hide_data",
			showMADswitch: "fresh-field-wrapper hide_data",
			showNewDeliveryBlk: "fresh-field-wrapper hide_data",
			showQtyTable: "table-mini hide_data",
			newValueFieldType: "text",
			newValueReadOnly: false,
			request_zone: "",
			request_type: "",
			requestTypeList: "",
			requestorTypeList: "",
			requestReasonList: "",
			zone_label: "",
			textList: "",
			partner_list: "",
			truck_trailer_list: "",
			request_flow: "3",
			requester_type: "",
			new_shipping_point: "",
			old_value: "",
			new_value: "",
			partner_name: "",
			text_type: "",
			requester_email: this.props.email,
			sales_order: "",
			quotation: "",
			request_reason: "",
			plant_mapping: "",
			sales_org: "",
			shipping_point_mapping: "",
			buycoList: "",
			selectedOption_textAction: "",
			selectedOption_MADswitch: "woMAD",
			portDoorIndicatorList: "",
			new_delivery_blk: "Z2",
			po_date: null,
			customer_text: "",
			additional_Information: "",
			additional_notification_to: "",
			filesData: [],
			selectedOption_sendZACK: "false",
			plant: "",
			quantity: "",
			po_num: "",
			showRejTable: false,
			rejectItems: [],
			fillInItem: "",
			nonSelectable: [],
			showRepricing: false,
			showCorrectTransfer: false,
			correctTransfer: "",
			loading: false,
			loadingText: "",
			showSwitchTable: false,
			showAdditionTable: false,
			addItemCount: 1,
		};
	}

	togglePopup = () => {
		this.setState({
			showPopup: !this.state.showPopup,
		});
	};

	pullData = () => {
		AxiosApi
			.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getRequestType`)
			.then((res) => {
				this.setState({
					requestTypeList: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});

		AxiosApi
			.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getRequesterType`)
			.then((res) => {
				this.setState({
					requestorTypeList: res.data.filter(x => x.value !== 11),
				});
			})
			.catch((err) => {
				console.log(err);
			});

		AxiosApi
			.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getRequestReason`)
			.then((res) => {
				this.setState({
					requestReasonList: res.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	componentDidMount = () => {
		this.pullData();
	};

	onChangeZone = (e) => {
		if (e === 1 && this.state.request_type === 5) {
			this.setState({
				showNewShippingPoint: "fresh-field-wrapper",
			});
		} else {
			this.setState({
				showNewShippingPoint: "fresh-field-wrapper hide_data",
			});
		}
	};

	onChangeRequestType = (e) => {
		this.setState({
			request_type: e.value,
			old_value: "",
			sales_order: "",
			showCorrectTransfer: false,
		});

		if (e.value === 5 && this.state.request_zone === 1) {
		//	console.log("comment")
			this.setState({
				showNewShippingPoint: "fresh-field-wrapper",
			});
		} else {
			this.setState({
				showNewShippingPoint: "fresh-field-wrapper hide_data",
			});
		}

		if (e.value === 1) {
			this.setState({
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
				new_value: "",
				old_value: "",
			});
		} else {
			this.setState({
				showNewValue: "fresh-field-wrapper",
				showOldValue: "fresh-field-wrapper",
			});
		}

		if (e.value === 9 || e.value === 10 || e.value === 16) {
			this.setState({
				showOldValue: "fresh-field-wrapper hide_data",
			});
		}

		if (e.value === 13) {
			this.setState({
				showPartnerFunction: "fresh-field-wrapper",
				showOldValue: "fresh-field-wrapper hide_data",
			});

			AxiosApi
				.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getPartnerFunc`)
				.then((res) => {
					this.setState({
						partner_list: res.data,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.setState({
				showPartnerFunction: "fresh-field-wrapper hide_data",
			});
		}

		if (e.value === 15) {
			this.setState({
				showMADswitch: "fresh-field-wrapper",
				showNewDeliveryBlk: "fresh-field-wrapper",
				showNewValue: "fresh-field-wrapper hide_data",
			});
		} else {
			this.setState({
				showMADswitch: "fresh-field-wrapper hide_data",
				showNewDeliveryBlk: "fresh-field-wrapper  hide_data",
				// showNewValue: "fresh-field-wrapper",
			});
		}

		if (e.value === 17) {
			this.setState({
				showTextTypes: "fresh-field-wrapper",
				showTextActionTypes: "fresh-field-wrapper",
				showOldValue: "fresh-field-wrapper hide_data",
				selectedOption_textAction: "Append",
			});

			AxiosApi
				.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getTextType`)
				.then((res) => {
					this.setState({
						textList: res.data,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.setState({
				showTextTypes: "fresh-field-wrapper hide_data",
				showTextActionTypes: "fresh-field-wrapper  hide_data",
			});
		}

		if (e.value !== 3) {
			this.setState({
				po_date: "",
				customer_text: "",
			});
		}

		if (e.value === 20) {
			this.setState({
				selectedOption_sendZACK: "false",
			});
		} else if (e.value !== 20) {
			this.setState({
				po_num: "",
				plant: "",
				new_shipping_point: "",
				quantity: "",
				selectedOption_sendZACK: "",
			});
		}

		if (e.value === 3 || e.value === 8) {
			this.setState({
				newValueFieldType: "date",
				new_value: "",
			});
		} else {
			this.setState({
				newValueFieldType: "text",
				new_value: "",
			});
		}

		if (e.value === 19) {
			this.setState({
				showQtyTable: "table-mini",
				filesData: [],
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
			});
		} else {
			this.setState({
				filesData: [],
				showQtyTable: "table-mini hide_data",
			});
		}
		if (e.value === 14) {
			this.setState({
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
				showRepricing: true,
			});
		} else {
			this.setState({
				showRepricing: false,
			});
		}
		if (e.value === 21) {
			this.setState({
				showRejTable: true,
				filesData: [],
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
			});
		} else {
			this.setState({
				filesData: [],
				showRejTable: false,
			});
		}
		if (e.value === 22) {
			this.setState({
				showSwitchTable: true,
				filesData: [],
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
			});
		} else {
			this.setState({
				filesData: [],
				showSwitchTable: false,
			});
		}
		if (e.value === 23) {
			this.setState({
				showAdditionTable: true,
				filesData: [],
				showNewValue: "fresh-field-wrapper hide_data",
				showOldValue: "fresh-field-wrapper hide_data",
			});
		} else {
			this.setState({
				filesData: [],
				showAdditionTable: false,
				addItemCount: 1,
			});
		}
	};

	onChangePlant_Mapping = (e) => {
		this.setState({
			new_value: e.label.substr(0, 4),
			shipping_point_mapping: "",
		});

		if (this.state.request_zone === 1) {
			const spData = {
				plant_code: e.label.substr(0, 4),
				request_zone: this.state.request_zone,
			};

			AxiosApi
				.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getShippingPoint`, spData)
				.then((res) => {
					this.setState({
						shipping_point_mapping: res.data,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	onChangeShipping_point_In_NewValue = (e) => {
		this.setState({
			new_value: e.label.substr(0, 4),
		});
	};

	onChangeBuycoList_In_NewValue = (e) => {
		this.setState({
			new_value: e.label,
		});
	};

	onChangeportDoorList_In_NewValue = (e) => {
		this.setState({
			new_value: e.label.substr(0, 2),
		});
	};

	onChangeTruckTrailerList_In_NewValue = (e) => {
		if (!e.label) {
			this.setState({
				new_value: e.label,
			});
		} else {
			const arr = e.label.split("-");
			const val = `${arr[0]}-${arr[1]}`;
			this.setState({
				new_value: val,
			});
		}
	};

	onChangeRequestFlow = (e) => {
		this.setState({
			request_flow: e.value,
		});
	};

	onChangeRequesterType = (e) => {
		this.setState({
			requester_type: e.value,
		});
	};

	onChangeNewShippingPoint = (e) => {
		this.setState({
			new_shipping_point: e.label.substr(0, 4),
		});
	};

	onChangeOldValue = (e) => {
		this.setState({
			old_value: e.target.value,
		});
	};

	onChangePONumber = (e) => {
		if (e.length > 20) {
			return;
		}
		if (!e.target.value.match(/^[A-Za-z0-9 ]*$/)) {
			return;
		}
		this.setState({
			po_num: e.target.value,
		});
	};

	onChangePlant = (e) => {
		this.setState({
			plant: e.label.substr(0, 4),
			shipping_point_mapping: "",
			new_shipping_point: "",
		});

		if (this.state.request_zone === 1) {
			const spData = {
				plant_code: e.label.substr(0, 4),
				request_zone: this.state.request_zone,
			};
			AxiosApi
				.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getShippingPoint`, spData)
				.then((res) => {
					this.setState({
						shipping_point_mapping: res.data,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	onchangeQuantity = (e) => {
		if (isNaN(e.target.value) && e.target.value !== "") {
		} else {
			this.setState({
				quantity: e.target.value,
			});
		}
	};

	onChangeNewValue = (e) => {
		const val = e.target.value;
		let exitFlag = false;
		if (this.state.request_type === 2) {
			if (isNaN(e.target.value) && e.target.value !== "") {
				exitFlag = true;
				return;
			}
		}
		if (this.state.request_type === 20) {
			if (!e.target.value.match(/^[a-z0-9]+$/i) && e.target.value !== "") {
				exitFlag = true;
				return;
			}
		}
		if (e.target.value.includes(".") === true) {
			exitFlag = true;
			return;
		}

		if (this.state.request_type === 7) {
			if (val.length > 6) {
				exitFlag = true;
			} else if (val.length === 6) {
				if (
					!(
						val.substr(0, 2).toUpperCase() === "MX" &&
						isNaN(val.substr(2, 1)) &&
						isNaN(val.substr(3, 1)) &&
						!isNaN(val.substr(4, 2))
					)
				) {
					this.setState({
						new_value: "",
					});
					return;
				}
			}
		} else if (this.state.request_type === 4) {
			if (val.length > 20) {
				exitFlag = true;
			}
		} else if (this.state.request_type === 5) {
			if (val.length > 4) {
				exitFlag = true;
			}
		} else if (this.state.request_type === 6) {
			if (val.length > 4) {
				exitFlag = true;
			}
		}
		// else if (
		// 	this.state.request_type === 12 ||
		// 	this.state.request_type === 11
		// ) {
		// 	if (
		// 		!val.match(/^[A-Za-z ]*$/) &&
		// 		(this.state.request_zone === 2 || this.state.request_zone === 3)
		// 	) {
		// 		exitFlag = true;
		// 	}
		// 	if (!val.match(/^[a-zA-Z0-9]*$/) && this.state.request_zone === 1) {
		// 		exitFlag = true;
		// 	}
		// 	if (
		// 		val.length === 5 &&
		// 		(this.state.request_zone === 2 || this.state.request_zone === 3) &&
		// 		val.substr(0, 2).toLowerCase() !==
		// 		this.state.destinationCountry.substr(0, 2).toLowerCase()
		// 	) {
		// 		this.setState({
		// 			new_value: "",
		// 		});
		// 		return;
		// 	}
		// 	if (
		// 		(val.length >= 6 &&
		// 			(this.state.request_zone === 2 || this.state.request_zone === 3)) ||
		// 		(val.length >= 7 && this.state.request_zone === 1)
		// 	) {
		// 		exitFlag = true;
		// 	}
		// }
		else if (this.state.request_type === 13) {
			if (!Number(val) && val !== "") {
				return;
			}
			if (val.length > 8) {
				return;
			}
		} else if (this.state.request_type === 16) {
			if (!Number(val) && val !== "") {
				return;
			}
			if (val.length > 4) {
				return;
			}
		} else if (this.state.request_type === 20) {
			if (val.length > 8) {
				return;
			}
		}

		if (exitFlag === true) {
			return;
		}
		if (this.state.request_type === 16) {
			this.setState({
				new_value: e.target.value.trim(),
			});
		} else {
			this.setState({
				new_value: e.target.value,
			});
		}
	};

	onChangePODate = (e) => {
		this.setState({
			po_date: e.target.value,
		});
	};

	onChangeMOQBlock = (e) => {
		this.setState({
			customer_text: e.target.value,
		});
	};

	onChangePartnerFuction = (e) => {
		this.setState({
			partner_name: e.value,
		});
	};

	onChangeTextList = (e) => {
		this.setState({
			text_type: e.value,
		});
	};

	getZone = (plant_id) => {
		console.log("inside get zone");
		AxiosApi
			.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getZone`, {
				headers: {
					plant: plant_id,
				},
			})
			.then((res) => {
				console.log(res.data);

				this.setState({
					zone_label: res.data[0].zone_name,
					request_zone: res.data[0].zone_value,
				});
				return res.data[0].zone_value;
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onchangeSalesOrder = (e) => {
		if (!Number(e.target.value) && e.target.value !== "") {
			return;
		}
		if (e.target.value.includes(".") === true) {
			return;
		}
		if (e.target.value.length > 8) {
			return;
		}
		this.setState({
			sales_order: e.target.value,
		});

		const val = { value: e.target.value };
		if (e.target.value.length >= 8) {
			this.setState({
				loading: true,
				loadingText: "Featching Order Info",
				nonSelectable: [],
				filesData: [],
				rejectItems: [],
				fillInItem: "",
			});
			AxiosApi
				.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/findSo`, val)
				.then((res) => {
					console.log(res.data[0]);
					if (res.data.length > 0) {
						AxiosApi
							.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getZone`, {
								headers: {
									plant: res.data[0].Delivery_Plant_ID,
								},
							})
							.then((response) => {
								this.setState({
									loading: false,
									loadingText: "",
									zone_label: response.data[0].zone_name,
									request_zone: response.data[0].zone_value,
								});
								this.onChangeZone(response.data[0].zone_value);
								if (this.state.request_type === 18) {
									this.setState({
										old_value: res.data[0].truck_trailer_type,
										newValueFieldType: "truck_trailer",
									});
									AxiosApi
										.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getTruckTrailer`, {
											request_zone: response.data[0].zone_value,
										})
										.then((res) => {
											this.setState({
												truck_trailer_list: res.data,
											});
										})
										.catch((err) => {
											console.log(err);
										});
								}
							})
							.catch((err) => {
								this.setState({
									loading: false,
									loadingText: "",
								});
								console.log(err);
							});

						if (res.data[0].Quotation === null) {
							this.setState({
								quotation: "",
							});
						} else {
							this.setState({
								quotation: res.data[0].Quotation,
							});
						}
						this.setState({
							sales_org: res.data[0].sales_org,
						});

						if (this.state.request_type === 4) {
							this.setState({
								old_value: res.data[0].Customer_Ref_PO,
							});
						} else if (this.state.request_type === 3) {
							if (
								Moment(res.data[0].Mad).format("DD-MM-YYYY") === "Invalid date"
							) {
								this.setState({
									old_value: "Dates are not aligned",
								});
							} else {
								this.setState({
									old_value: Moment(res.data[0].Mad).format("DD-MM-YYYY"),
								});
							}
						} else if (this.state.request_type === 5) {
							this.setState({
								old_value: res.data[0].Delivery_Plant_ID,
							});

							const reqData = {
								sales_org: res.data[0].sales_org,
								Delivery_Plant_ID: res.data[0].Delivery_Plant_ID,
							};
							AxiosApi
								.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getPlant`, reqData, {
									headers: {
										user: this.state.requester_email,
									},
								})
								.then((res) => {
									this.setState({
										plant_mapping: res.data,
										newValueFieldType: "plant",
									});
								})
								.catch((err) => {
									console.log(err);
								});
						} else if (this.state.request_type === 6) {
							this.setState({
								old_value: res.data[0].Shipping_Point,
							});
							const spData2 = {
								plant_code: res.data[0].Delivery_Plant_ID,
								request_zone: this.state.request_zone,
							};
							AxiosApi
								.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getShippingPoint`, spData2)
								.then((res) => {
									console.log(res);
									this.setState({
										shipping_point_mapping: res.data,
										newValueFieldType: "shipping point",
									});
								})
								.catch((err) => {
									console.log(err);
								});
						} else if (this.state.request_type === 7) {
							this.setState({
								old_value: res.data[0].Route,
							});
						} else if (this.state.request_type === 2) {
							this.setState({
								old_value: res.data[0].quantity_orderd,
								newValueReadOnly: false,
							});
						} else if (this.state.request_type === 8) {
							if (
								Moment(res.data[0].po_date).format("DD-MM-YYYY") ===
								"Invalid date"
							) {
								this.setState({
									old_value: "Dates are not aligned",
								});
							} else {
								this.setState({
									old_value: Moment(res.data[0].po_date).format("DD-MM-YYYY"),
								});
							}
						} else if (this.state.request_type === 9) {
							AxiosApi
								.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getBuyco`)
								.then((res) => {
									// then print response status
									this.setState({
										buycoList: res.data,
										newValueFieldType: "buyco",
									});
								})
								.catch((err) => {
									console.log(err);
								});
						} else if (this.state.request_type === 10) {
							AxiosApi
								.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getPortDoor`)
								.then((res) => {
									// then print response status
									this.setState({
										portDoorIndicatorList: res.data,
										newValueFieldType: "portdoor",
									});
								})
								.catch((err) => {
									console.log(err);
								});
						} else if (this.state.request_type === 11) {
							this.setState({
								old_value: res.data[0].unload_pt,
							});
						} else if (this.state.request_type === 12) {
							this.setState({
								old_value: res.data[0].receive_pt,
							});
						} else if (this.state.request_type === 15) {
							this.setState({
								old_value: res.data[0].Delivery_Blk,
							});
						} else if (this.state.request_type === 19) {
							const keys_to_keep = ["SKU_Item", "Material", "quantity_orderd"];
							const subset = (array) =>
								array.map((o) =>
									keys_to_keep.reduce((acc, curr) => {
										acc[curr] = o[curr];
										return acc;
									}, {})
								);
							const filterData = subset(res.data).filter(
								(x) => x.SKU_Item.slice(-1) !== "1"
							);
							const result = filterData.map((a) => {
								a.New_Qty = "";
								return a;
							});
							console.log(result);
							this.setState({
								filesData: result.filter((x) => x.SKU_Item.slice(-1) !== "1"),
								showQtyTable: "table-mini",
							});
						}
						else if (this.state.request_type === 22) {
							const keys_to_keep = ["SKU_Item", "Material", "quantity_orderd"];
							const subset = (array) =>
								array.map((o) =>
									keys_to_keep.reduce((acc, curr) => {
										acc[curr] = o[curr];
										return acc;
									}, {})
								);
							const filterData = subset(res.data).filter(
								(x) => x.SKU_Item.slice(-1) !== "1"
							);
							const result = filterData.map((a) => {
								a.New_Qty = "";
								a.New_Mat = '';
								a.newQuantity = '';
								return a;
							});
							this.setState({
								filesData: result.filter((x) => x.SKU_Item.slice(-1) !== "1"),
							});
						}
						else if (this.state.request_type === 23) {
							const keys_to_keep = ["SKU_Item", "Material", "quantity_orderd"];
							const subset = (array) =>
								array.map((o) =>
									keys_to_keep.reduce((acc, curr) => {
										acc[curr] = o[curr];
										return acc;
									}, {})
								);
							const filterData = subset(res.data).filter(
								(x) => x.SKU_Item.slice(-1) !== "1"
							);
							const result = filterData.map((a) => {
								a.New_Qty = "";
								return a;
							});
							this.setState({
								filesData: result.filter((x) => x.SKU_Item.slice(-1) !== "1"),
							});
						}
						else if (this.state.request_type === 21) {
							const keys_to_keep = ["SKU_Item", "Material", "quantity_orderd"];
							const subset = (array) =>
								array.map((o) =>
									keys_to_keep.reduce((acc, curr) => {
										acc[curr] = o[curr];
										return acc;
									}, {})
								);
							const filterData = subset(res.data).filter(
								(x) => x.SKU_Item.slice(-1) !== "1"
							);
							const result = filterData.map((a) => {
								a.New_Qty = "";
								return a;
							});
							console.log(filterData);
							const filesdata = result.filter(
								(x) => x.SKU_Item.slice(-1) !== "1"
							);
							if (filesdata.length === 1) {
								this.setState({ nonSelectable: [filesdata[0].SKU_Item] });
							}
							this.setState({
								filesData: filesdata,
							});
						} else if (this.state.request_type === 20) {
							this.setState({
								old_value: res.data[0].sold_to,
							});

							const reqData = {
								sales_org: res.data[0].sales_org,
								Delivery_Plant_ID: res.data[0].Delivery_Plant_ID,
							};
							AxiosApi
								.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getPlant`, reqData, {
									headers: {
										user: this.state.requester_email,
									},
								})
								.then((res) => {
									this.setState({
										plant_mapping: res.data,
									});
								})
								.catch((err) => {
									console.log(err);
								});
						}
					} else {
						this.setState({
							loading: false,
							loadingText: "",
						});
						toast.warning(
							"No data found for the sales order or order already cancelled"
						);
					}
				})
				.catch((err) => {
					this.setState({
						loading: false,
						loadingText: "",
					});
				});
			AxiosApi
				.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/fetchZCDP`, val)
				.then((response) => {
					const distCoun = response.data[0];
					if (distCoun) {
						this.setState({
							destinationCountry: distCoun["Destination Country"],
						});
					}
				});
		} else {
			this.setState({
				old_value: "",
			});
		}
	};

	onChangeQuotation = (e) => {
		this.setState({
			quotation: e.target.value,
		});
	};

	onChangeRequestReason = (e) => {
		console.log(this.state);
		this.setState({
			request_reason: e.value,
		});
	};

	onchangeAdditionalInformation = (e) => {
		this.setState({
			additional_Information: e.target.value,
		});
	};

	onTextActionChange = (e) => {
		this.setState({
			selectedOption_textAction: e.target.value,
		});
	};

	onSendZACKChange = (e) => {
		this.setState({
			selectedOption_sendZACK: e.target.value,
		});
	};

	onMADSwitchChange = (e) => {
		if (e.target.value === "wMAD") {
			this.setState({
				showNewValue: "fresh-field-wrapper",
				newValueFieldType: "date",
			});
		} else {
			this.setState({
				showNewValue: "fresh-field-wrapper hide_data",
				new_value: "",
			});
		}
		this.setState({
			selectedOption_MADswitch: e.target.value,
		});
	};

	onchangeAdditionalNotificationTo = (e) => {
		this.setState({
			additional_notification_to: e.target.value,
		});
	};

	downloadExcelFile = (fileName) => {
		AxiosApi({
			url: `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/exportTemp/`, // your url
			method: "POST",
			responseType: "blob", // important
			data: {
				file: fileName,
			}
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				if (fileName === '/template.xlsx') {
					link.setAttribute("download", "Download.xlsx"); // or any other extension
				}
				else {
					link.setAttribute("download", "Download_SKU.xlsx");
				}
				document.body.appendChild(link);
				link.click();
				toast.success("Template downloaded successfully.");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	updateFilesData = (data) => {
		this.setState({
			filesData: data,
		});
	};

	onSubmit = async (data) => {

		this.setState({ isFormSubmitted: true });

		// if (this.state.request_type === "") {
		// 	toast.error("Request Type Cannot Be Empty");
		// 	return;
		// }
		// if (this.state.requester_type === "") {
		// 	toast.error("Requestor Type Cannot Be Empty");
		// 	return;
		// }
		// if (this.state.sales_order === "") {
		// 	toast.error("Sales Order Cannot Be Empty");
		// 	return;
		// }
		// if (this.state.request_reason === "") {
		// 	toast.error("Request Reason Cannot Be Empty");
		// 	return;
		// }
		// if (this.state.request_type === 14 && this.state.new_value === "") {
		// 	toast.error("Please select Repricing value");
		// 	return;
		// }
		// if (this.state.request_type === 16 && this.state.new_value.length !== 4) {
		// 	toast.error("New Value Length must be 4 digit");
		// 	return;
		// }

		// if (
		// 	this.state.new_shipping_point === "" &&
		// 	this.state.request_type === 5 &&
		// 	this.state.request_zone === 1
		// ) {
		// 	toast.error("New Shipping Point Cannot Be Empty");
		// 	return;
		// }

		// if (this.state.request_type === 21 && this.state.rejectItems.length === 0) {
		// 	toast.error("Please select an item for Rejection");
		// 	return;
		// }

		const val = { value: this.state.sales_order };
		this.setState({
			loading: true,
			loadingText: 'Checking Duplicates',
		})
		await AxiosApi
			.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/findSo`, val)
			.then((res) => {
				if (res.data.length > 0) {
					AxiosApi
						.post(
							`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/GetDuplicate`,
							this.state
						)
						.then((res) => {
							// then print response status
							if (res.data[0].column0 === 0) {
								if (this.state.newValueFieldType === "date") {
									this.setState({
										new_value: Moment(this.state.new_value).format(
											"DD-MM-YYYY"
										),
									});
								}

								if (this.state.po_date !== null) {
									this.setState({
										po_date: Moment(this.state.po_date).format("DD-MM-YYYY"),
									});
								}
								if (
									(this.state.new_value !== "" &&
										this.state.new_value !== "Invalid date") ||
									this.state.request_type === 1 ||
									(this.state.request_type === 15 &&
										this.state.selectedOption_MADswitch === "woMAD") ||
									this.state.request_type === 19 ||
									this.state.request_type === 12 ||
									this.state.request_type === 21 ||
									this.state.request_type === 22 ||
									this.state.request_type === 23
								) {
									if (this.state.request_type === 3) {
										const testVal = Moment(
											this.state.new_value,
											"DD-MM-YYYY",
											true
										).format("MM/DD/YYYY");
										const x1 = Moment().format("MM/DD/YYYY");
										const date1 = new Date(x1);
										const date2 = new Date(testVal);
										const timeDiff =
											Math.abs(date2.getTime()) - Math.abs(date1.getTime());
										const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
										if (diffDays < 0) {
											toast.error("Invalid new value");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}
									} else if (this.state.request_type === 13) {
										if (this.state.new_value.length !== 8) {
											toast.error("New value must be 8 digits");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}
										if (this.state.partner_name === "") {
											toast.error("Partner cannot be blank");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}
									} else if (this.state.request_type === 19) {
										let Data = [];
										Data = this.state.filesData.filter(
											(x) =>
												x.New_Qty !== "" &&
												x.New_Qty !== "0" &&
												x.New_Qty !== x.quantity_orderd
										);
										if (Data.length === 0) {
											toast.error("Incorrect New Qty");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}
									}
									else if (this.state.request_type === 22) {
										let Data = [];
										Data = this.state.filesData.filter(
											(x) =>
												x.New_Mat !== "" &&
												x.New_Mat !== "0" &&
												x.New_Mat !== x.Material
										);
										if (Data.length === 0) {
											toast.error("Incorrect New Qty");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}

										Data = this.state.filesData.map(x => {
											x.New_Qty = x.New_Mat + (x.newQuantity !== '' ? '-' : '')
												+ x.newQuantity;
											this.setState({
												loading: false,
												loadingText: '',
											})
											return x;
										});

										this.updateFilesData(Data);
									}
									else if (this.state.request_type === 23) {
										let Data = this.state.filesData.filter(
											(x) =>
												x.Material !== "" &&
												x.Material !== "0" &&
												x.quantity_orderd !== '' &&
												x.quantity_orderd !== '0'
										);

										let checkNewCount = Data.filter(x => !(typeof (x.SKU_Item) === 'string'));

										if (checkNewCount.length === 0) {
											toast.error("Incorrect New Value");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}

										let checkDupli = Data.map(x => x.Material);
										const s = new Set(checkDupli);

										if (s.size !== checkDupli.length) {
											toast.error("Duplicate Materials");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										};

										let finalData = Data.map(x => {
											if (!(typeof (x.SKU_Item) === 'string')) {
												x.New_Qty = x.quantity_orderd;
												x.quantity_orderd = '';
											}
											this.setState({
												loading: false,
												loadingText: '',
											})
											return x;
										});

										this.updateFilesData(finalData);
									}
									else if (this.state.request_type === 20) {
										if (
											this.state.new_value.length !== 5 &&
											this.state.new_value.length !== 8
										) {
											toast.error("Incorrect New Value");
											this.setState({
												loading: false,
												loadingText: '',
											})
											return;
										}
									}
									const val = { value: this.state.sales_order };
									this.setState({
										loading: true,
										loadingText: "Creating Request",
									});

									// const zcdp = AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/fetchZCDP`, val)
									// 	.then((response) => {
									// 		AxiosApi
									// 			.post(
									// 				`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/updateZCDP`,
									// 				response.data
									// 		)
									// 	});
									// const zxxvl = AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/fetchZXXVL`, val)
									// 	.then((response) => {
									// 		AxiosApi
									// 			.post(
									// 				`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/updateZXXVL`,
									// 				response.data
									// 			)
									// 	});
									// const reqDetail = AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/add/new`,this.state);

									// Promise.all([zcdp, zxxvl, reqDetail])
									// 	.then((responses) => {
									// 		const responesThree = responses[2];
									// 		this.setState({
									// 			loading: false,
									// 			loadingText: "",
									// 		});
									// 		setTimeout(
									// 			() => window.location.reload(),
									// 			5000
									// 		);
									// 		toast.success(
									// 			`Request Number - ${responesThree.data} raised successfully`
									// 		);
									// 	})
									// 	.catch((err) => {
									// 		console.log(err);
									// 	})

									AxiosApi
										.post(
											`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/fetchZXXVL`,
											val
										)
										.then((ZXXVLres) => {
											let rejectedItemList = [];
											const zxxvlData = ZXXVLres.data.filter(x => {
												let res = (x['Reason Rej'] === null ||
													x['Reason Rej'] === undefined ||
													x['Reason Rej'].length === 0)
												if (!res) rejectedItemList.push(x['Line Item No']);
												return res;
											})
											AxiosApi
												.post(
													`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/updateZXXVL`,
													zxxvlData
												)
												.then((res) => {
													AxiosApi
														.post(
															`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/fetchZCDP`,
															val
														)
														.then((ZCDPres) => {
															let zcdpData = ZCDPres.data.filter(x => !rejectedItemList.includes(x['SalesOrderLineID']))
															AxiosApi
																.post(
																	`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/filterData/updateZCDP`,
																	zcdpData
																)
																.then((res) => {
																	AxiosApi
																		.post(
																			`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/add/new`,
																			this.state
																		)
																		.then((res) => {
																			this.setState({
																				loading: false,
																				loadingText: "",
																			});
																			setTimeout(
																				() => window.location.reload(),
																				5000
																			);
																			toast.success(
																				`Request Number - ${res.data} raised successfully`
																			);
																		})
																		.catch((err) => {
																			console.log(err);
																			this.setState({
																				loading: false,
																				loadingText: "",
																			});
																			toast.error("Error in upload.");
																		});
																})
																.catch((err) => {
																	console.log(err);
																	this.setState({
																		loading: false,
																		loadingText: "",
																	});
																	toast.error("Error in inserting zxxvl");
																});
														})
														.catch((err) => {
															this.setState({
																loading: false,
																loadingText: "",
															});
															console.log(err);
															toast.error("Error in fetching zxxvl");
														});
												})
												.catch((err) => {
													this.setState({
														loading: false,
														loadingText: "",
													});
													console.log(err);
													toast.error("Error in inserting zcdp");
												});
										})
										.catch((err) => {
											this.setState({
												loading: false,
												loadingText: "",
											});
											console.log(err);
											toast.error("Error in fetching zcdp");
										});
								} else {
									toast.error("Incorrect New Value");
									this.setState({
										loading: false,
										loadingText: '',
									})
								}
							} else {
								console.log(res.data[0]);
								toast.warning(
									"There is already a request in server with same details."
								);
								this.setState({
									loading: false,
									loadingText: '',
								})
							}
						})
						.catch((err) => {
							// then print response status
							console.log(err);
							toast.error("Error in upload.");
							this.setState({
								loading: false,
								loadingText: '',
							});
						});
				} else {
					toast.error(
						"No Data found for the Sales Order. Unable to submit request."
					);
					this.setState({
						loading: false,
						loadingText: '',
					})
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
					loadingText: '',
				});
			});
		this.setState({ isFormSubmitted: false });
	};

	onCancel = () => {
		window.location.reload(true);
	};

	onChangeRepricing = (e) => {
		if (e.value === "Transfer") {
			this.setState({ showCorrectTransfer: true });
		} else {
			this.setState({ showCorrectTransfer: false });
		}
		this.setState({ new_value: e.value });
	};

	handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			if (this.state.filesData.length - 2 === this.state.rejectItems.length) {
				const arr = this.state.filesData.filter(
					(x) =>
						!(
							x.SKU_Item === row.SKU_Item ||
							this.state.rejectItems.includes(x.SKU_Item)
						)
				);
				this.setState({
					nonSelectable: [...this.state.nonSelectable, arr[0].SKU_Item],
				});
			}
			this.setState({
				rejectItems: [...this.state.rejectItems, row.SKU_Item],
				filesData: this.state.filesData.map((x) => {
					if (x.SKU_Item === row.SKU_Item) x.New_Qty = "SKU to be Rejected";
					return x;
				}),
			});
			document.getElementById(row.SKU_Item).disabled = true;
		} else {
			if (this.state.filesData.length - 1 === this.state.rejectItems.length) {
				this.setState({
					nonSelectable: [this.state.fillInItem],
				});
			}
			document.getElementById(row.SKU_Item).disabled = false;
			this.setState({
				rejectItems: this.state.rejectItems.filter((x) => x !== row.SKU_Item),
				filesData: this.state.filesData.map((x) => {
					if (x.SKU_Item === row.SKU_Item) x.New_Qty = "";
					return x;
				}),
			});
		}
	};

	onChangeCorrectTransfer = (e) => {
		if (e.target.value === "") {
			this.setState({ new_value: "Transfer" });
		} else {
			const a = e.target.value.trim();

			this.setState({
				new_value: `Transfer-${a}`,
			});
		}
	};

	addItems = () => {
		const count = this.state.addItemCount;
		this.setState({
			filesData: [...this.state.filesData, {
				Material: "",
				New_Qty: "",
				SKU_Item: count,
				quantity_orderd: ''
			}],
			addItemCount: count + 1,
		})
	}

	removeItems = () => {
		if (this.state.addItemCount !== 1) {
			this.setState({
				filesData: this.state.filesData.slice(0, this.state.filesData.length - 1),
				addItemCount: this.state.addItemCount - 1,
			})
		}
	}

	render() {
		const columns = [
			{
				dataField: "SKU_Item",
				text: "Item",
				sort: true,
			},
			{
				dataField: "Material",
				text: "Material",
				editable: false,
			},
			{
				dataField: "quantity_orderd",
				text: "Old Qty(pcs)",
				editable: false,
			},
			{
				dataField: "New_Qty",
				text: "New Qty(pcs)",
			},
		];

		const formatter = (cell, row) => {
			const handleRadio = () => {
				console.log(row);
				this.setState({
					fillInItem: row.SKU_Item,
					nonSelectable: [row.SKU_Item],
					filterData: this.state.filesData.map((x) => {
						if (x.New_Qty === "Fill In" && x.SKU_Item !== row.SKU_Item) {
							x.New_Qty = "";
						} else if (x.SKU_Item === row.SKU_Item) {
							x.New_Qty = "Fill In";
						}
						return x;
					}),
				});
			};
			const handleClick = (e) => {
				console.log(e.target.checked);
				if (e.target.id === this.state.fillInItem) {
					document.getElementById(e.target.id).checked = false;
					if (
						this.state.rejectItems.length !==
						this.state.filterData.length - 1
					) {
						this.setState({
							nonSelectable: [],
						});
					}
					this.setState({
						fillInItem: "",
						filterData: this.state.filesData.map((x) => {
							if (x.SKU_Item === e.target.id) {
								x.New_Qty = "";
							}
							return x;
						}),
					});
				}
				console.log(e.target.id);
			};

			return (
				<div onChange={handleRadio}>
					<input
						onClick={handleClick}
						id={row.SKU_Item}
						type="radio"
						value={row.SKU_Item}
						name="reject"
						disabled={this.state.filesData.length === 1}
					/>
				</div>
			);
		};

		const selectRow = {
			mode: "checkbox",
			nonSelectable: this.state.nonSelectable,
			onSelect: this.handleOnSelect,
			hideSelectAll: true,
		};
		const customStyles = {
			input: () => ({
				height: '38px',
				padding: '10px 0',
			})
		}
		const rejColumns = [
			{
				dataField: "SKU_Item",
				text: "Item",
				sort: true,
			},
			{
				dataField: "Material",
				text: "Material",
			},
			{
				dataField: "quantity_orderd",
				text: "Quantity"
			},
			{
				dataField: "Action",
				text: "Fill in the Order",
				formatter,
			},
		];

		const addColumn = [
			{
				dataField: "SKU_Item",
				text: "Item",
				sort: true,
			},
			{
				dataField: "Material",
				text: "Material",
				editable: (content, row, rowIndex, columnIndex) => !(typeof (row.SKU_Item) === 'string')
			},
			{
				dataField: "quantity_orderd",
				text: "Quantity",
				editable: (content, row, rowIndex, columnIndex) => !(typeof (row.SKU_Item) === 'string')
			},
		]

		const switchColumns = [
			{
				dataField: "SKU_Item",
				text: "Item",
				sort: true,
			},
			{
				dataField: "Material",
				text: "Material",
				editable: false,
			},
			{
				dataField: "quantity_orderd",
				text: "Quantity",
				editable: false,
			},
			{
				dataField: "New_Mat",
				text: "New Matarial",
			},
			{
				dataField: "newQuantity",
				text: "New Quantity",
			},
		];

		const repricingType = [
			{ label: "Transfer Price Update", value: "Transfer" },
			{ label: "General Repricing", value: "General" },
		];
		function indication() {
			return <div style={{ color: 'grey' }}>No Data to Show</div>
		}

		return (
			<LoadingOverlay
				active={this.state.loading}
				spinner
				text={this.state.loadingText}
			>
				<div className="container my-3">
					<div className="UploaderNreview">
						{this.state.showPopup && (
							<FileUpload
								id="FileUpload"
								text='Click "Close Button" to hide popup'
								closePopup={this.togglePopup}
								email={this.state.requester_email}
							/>
						)}
					</div>

					<div className="reqForm">
						<div className="form-header">
							Request Form
						</div>
						{/* <h5 className="reminder">* Mandatory fields</h5> */}
						<div className="userDetails">
							<div className="fresh-field-wrapper">
								<label className="fresh-label" htmlFor="fresh-request_type">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Request Type
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.requestTypeList}
									onChange={this.onChangeRequestType}
								/>
							</div>

							<div className="fresh-field-wrapper">
								<label className="fresh-label" htmlFor="fresh-requester_type">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Requestor Type
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.requestorTypeList}
									onChange={this.onChangeRequesterType}
								/>
							</div>

							<div className="fresh-field-wrapper">
								<label className="fresh-label" htmlFor="fresh-sales_order">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Sales Order
									</span>
								</label>
								<input
									style={{ cursor: 'text' }}
									id="fresh-sales_order-user-profile"
									className="fresh-input css-yk16xz-control"
									onChange={this.onchangeSalesOrder}
									required
									type="text"
									value={this.state.sales_order}
								/>
							</div>

							<div className="fresh-field-wrapper request_zone">
								<label className="fresh-label" htmlFor="fresh-request_zone">
									<span className="fresh-title">
										<span className="reminder">* </span> Zone
									</span>
								</label>
								<input
									id="fresh-zone-user-profile"
									className="fresh-input css-yk16xz-control"
									onChange={this.onChangeZone}
									value={this.state.zone_label}
									readOnly
								/>
							</div>

							{this.state.showRepricing && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-request_type">
										<span className="fresh-title">
											<span className="reminder">* </span>
											Repricing Type
										</span>
									</label>
									<Select
										styles={customStyles}
										options={repricingType}
										onChange={this.onChangeRepricing}
									/>
								</div>
							)}

							{this.state.showCorrectTransfer && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-old_value">
										<span className="fresh-title">
											Correct Transfer Price
										</span>
									</label>
									<input
										id="fresh-old_value-user-profile"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangeCorrectTransfer}
									/>
								</div>
							)}

							<div className={this.state.showQtyTable}>
								<label className="fresh-label" htmlFor="fresh-request_zone">
									<span className="fresh-title">
										<span className="reminder">* </span> Enter New Qty Below
									</span>
								</label>

								<BootstrapTable
									bootstrap4
									noDataIndication={indication}
									keyField="SKU_Item"
									data={this.state.filesData}
									columns={columns}
									cellEdit={cellEditFactory({
										mode: "click",
										blurToSave: true,
									})}
									headerClasses="cust_header-class"
								/>
							</div>

							{this.state.showRejTable && (
								<div className="table-mini">
									<label className="fresh-label" htmlFor="fresh-request_zone">
										<span className="fresh-title">
											<span className="reminder">* </span> Select items to reject
											and fill in the order
										</span>
									</label>

									<BootstrapTable
										bootstrap4
										noDataIndication={indication}
										key={this.state.filesData}
										keyField="SKU_Item"
										data={this.state.filesData}
										columns={rejColumns}
										selectRow={selectRow}
										headerClasses="cust_header-class"
									/>
								</div>
							)}

							{this.state.showSwitchTable && (
								<div className="table-mini">
									<label className="fresh-label" htmlFor="fresh-request_zone">
										<span className="fresh-title">
											<span className="reminder">* </span> Select items to switch

										</span>
									</label>

									<BootstrapTable
										bootstrap4
										noDataIndication={indication}
										key={this.state.filesData}
										keyField="SKU_Item"
										data={this.state.filesData}
										columns={switchColumns}
										headerClasses="cust_header-class"
										cellEdit={cellEditFactory({
											mode: "click",
											blurToSave: true,
										})}
									/>
								</div>
							)}

							{this.state.showAdditionTable && (
								<div className="table-mini">
									<label className="fresh-label" htmlFor="fresh-request_zone">
										<span className="fresh-title">
											<span className="reminder">* </span> Add Items

										</span>
									</label>

									<BootstrapTable
										bootstrap4
										noDataIndication={indication}
										keyField="SKU_Item"
										data={this.state.filesData}
										columns={addColumn}
										headerClasses="cust_header-class"
										cellEdit={cellEditFactory({
											mode: "click",
											blurToSave: true,
										})}
									/>
									{this.state.filesData.length > 0 &&
										<div style={{ paddingBottom: '20px' }}><button style={{ width: '97px' }}
											className="fresh-button cust_btn-cls"
											onClick={this.addItems}
										>
											Add
										</button>
											<button
												className="fresh-button cust_btn-cls"
												onClick={this.removeItems}
											>
												Remove
											</button> </div>}
								</div>

							)}

							<div className={this.state.showOldValue}>
								<label className="fresh-label" htmlFor="fresh-old_value">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Old Value
									</span>
								</label>
								<input
									id="fresh-old_value-user-profile"
									className="fresh-input css-yk16xz-control"
									onChange={this.onChangeOldValue}
									value={this.state.old_value}
									readOnly
								/>
							</div>

							<div className={this.state.showPartnerFunction}>
								<label className="fresh-label" htmlFor="fresh-partner-function">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Partner Function
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.partner_list}
									onChange={this.onChangePartnerFuction}
								/>
							</div>

							<div className={this.state.showTextTypes}>
								<label className="fresh-label" htmlFor="fresh-text-to-be-updated">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Text To Be Updated
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.textList}
									onChange={this.onChangeTextList}
								/>
							</div>

							<div style={{ paddingTop: "30px" }} className={this.state.showTextActionTypes}>
								<label className="radio-inline" htmlFor="replace">
									<input
										type="radio"
										value="Replace"
										checked={this.state.selectedOption_textAction === "Replace"}
										onChange={this.onTextActionChange}
										id="replace"
									/>
									<span className="label-body"> Replace</span>
								</label>
								<label className="radio-inline" htmlFor="append">
									<input
										type="radio"
										value="Append"
										checked={this.state.selectedOption_textAction === "Append"}
										onChange={this.onTextActionChange}
										id="append"
									/>
									<span className="label-body"> Append </span>
								</label>
							</div>

							<div style={{ paddingTop: "30px" }} className={this.state.showMADswitch}>
								<label className="radio-inline" htmlFor="wMAD">
									<input
										type="radio"
										value="wMAD"
										checked={this.state.selectedOption_MADswitch === "wMAD"}
										onChange={this.onMADSwitchChange}
										id="wMAD"
									/>
									<span className="label-body"> w/ MAD</span>
								</label>
								<label className="radio-inline" htmlFor="woMAD">
									<input
										type="radio"
										value="woMAD"
										checked={this.state.selectedOption_MADswitch === "woMAD"}
										onChange={this.onMADSwitchChange}
										id="woMAD"
									/>
									<span className="label-body"> w/o MAD </span>
								</label>
							</div>

							<div className={this.state.showNewDeliveryBlk}>
								<label className="fresh-label" htmlFor="fresh-old_value">
									<span className="fresh-title">
										<span className="reminder">* </span>
										New Delivery Block
									</span>
								</label>
								<input
									id="fresh-delivery_blk-user-profile"
									className="fresh-input css-yk16xz-control"
									value={this.state.new_delivery_blk}
									readOnly
								/>
							</div>

							<div className={this.state.showNewValue}>
								<label className="fresh-label" htmlFor="fresh-new_value">
									<span className="fresh-title">
										<span className="reminder">* </span>
										New Value
									</span>
								</label>
								{this.state.newValueFieldType === "text" && (
									<input
										style={{ cursor: 'text' }}
										id="fresh-new_value-user-profile"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangeNewValue}
										type={this.state.newValueFieldType}
										readOnly={this.state.newValueReadOnly}
										value={this.state.new_value}
									/>
								)}
								{this.state.newValueFieldType === "date" && (
									<input
										id="fresh-new_value-user-profile"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangeNewValue}
										type={this.state.newValueFieldType}
										readOnly={this.state.newValueReadOnly}
										placeholder="DD-MM-YYYY"
										onKeyDown="return false"
										value={this.state.new_value}
										min={
											(this.state.request_type === 3 ||
												this.state.request_type === 15) &&
											Moment().format("YYYY-MM-DD")
										}
									/>
								)}
								{this.state.newValueFieldType === "plant" && (
									<Select
										styles={customStyles}
										options={this.state.plant_mapping}
										onChange={this.onChangePlant_Mapping}
									/>
								)}
								{this.state.newValueFieldType === "shipping point" && (
									<Select
										styles={customStyles}
										options={this.state.shipping_point_mapping}
										onChange={this.onChangeShipping_point_In_NewValue}
									/>
								)}
								{this.state.newValueFieldType === "buyco" && (
									<Select
										styles={customStyles}
										options={this.state.buycoList}
										onChange={this.onChangeBuycoList_In_NewValue}
									/>
								)}
								{this.state.newValueFieldType === "portdoor" && (
									<Select
										styles={customStyles}
										options={this.state.portDoorIndicatorList}
										onChange={this.onChangeportDoorList_In_NewValue}
									/>
								)}
								{this.state.newValueFieldType === "truck_trailer" && (
									<Select
										styles={customStyles}
										options={this.state.truck_trailer_list}
										onChange={this.onChangeTruckTrailerList_In_NewValue}
									/>
								)}
							</div>

							{this.state.request_type === 3 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-po_date">
										<span className="fresh-title">Update PO Date</span>
									</label>
									<input
										id="fresh-new_value-user-profile"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangePODate}
										type="date"
										placeholder="DD-MM-YYYY"
										onKeyDown="return false"
										value={this.state.po_date}
									/>
								</div>
							)}

							{this.state.request_type === 3 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-moq_block">
										<span className="fresh-title">Customer text for MOQ Block</span>
									</label>
									<input
										style={{ cursor: 'text' }}
										id="fresh-moq-block"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangeMOQBlock}
										type="text"
										value={this.state.customer_text}
									/>
								</div>
							)}

							{this.state.request_type === 20 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-po_num">
										<span className="fresh-title">PO Number</span>
									</label>
									<input
										style={{ cursor: 'text' }}
										id="fresh-moq-block"
										className="fresh-input css-yk16xz-control"
										onChange={this.onChangePONumber}
										type="text"
										value={this.state.po_num}
									/>
								</div>
							)}

							{this.state.request_type === 20 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-plant">
										<span className="fresh-title">Plant</span>
									</label>
									<Select
										styles={customStyles}
										options={this.state.plant_mapping}
										onChange={this.onChangePlant}
									/>
								</div>
							)}

							{this.state.request_type === 20 && this.state.request_zone === 1 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-plant">
										<span className="fresh-title">New Shipping Point</span>
									</label>
									<Select
										styles={customStyles}
										options={this.state.shipping_point_mapping}
										onChange={this.onChangeNewShippingPoint}
									/>
								</div>
							)}

							{this.state.request_type === 20 && (
								<div className="fresh-field-wrapper">
									<label className="fresh-label" htmlFor="fresh-plant">
										<span className="fresh-title">Quantity</span>
									</label>
									<input
										style={{ cursor: 'text' }}
										id="fresh-additional information-user-profile"
										className="fresh-input css-yk16xz-control"
										onChange={this.onchangeQuantity}
										type="text"
										value={this.state.quantity}
									/>
								</div>
							)}

							{this.state.request_type === 20 && (
								<div style={{ paddingTop: "30px" }} className="fresh-field-wrapper">
									<label className="radio-inline" htmlFor="true">
										<input
											type="radio"
											value="true"
											checked={this.state.selectedOption_sendZACK === "true"}
											onChange={this.onSendZACKChange}
											id="true"
										/>
										<span className="label-body"> Send ZACK</span>
									</label>
									<label className="radio-inline" htmlFor="false">
										<input
											type="radio"
											value="false"
											checked={this.state.selectedOption_sendZACK === "false"}
											onChange={this.onSendZACKChange}
											id="false"
										/>
										<span className="label-body"> Do not send ZACK</span>
									</label>
								</div>
							)}

							<div className={this.state.showNewShippingPoint}>
								<label className="fresh-label" htmlFor="fresh-new_shipping_point">
									<span className="fresh-title">
										<span className="reminder">* </span>
										New Shipping Point
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.shipping_point_mapping}
									onChange={this.onChangeNewShippingPoint}
								/>
							</div>

							<div className="fresh-field-wrapper">
								<label className="fresh-label" htmlFor="fresh-request_reason">
									<span className="fresh-title">
										<span className="reminder">* </span>
										Request Reason
									</span>
								</label>
								<Select
									styles={customStyles}
									options={this.state.requestReasonList}
									onChange={this.onChangeRequestReason}
								/>
							</div>

							<div className="fresh-field-wrapper">
								<label
									className="fresh-label"
									htmlFor="fresh-additional information"
								>
									<span className="fresh-title">
										<span className="reminder" />
										Additional Information
									</span>
								</label>
								<input
									style={{ cursor: 'text' }}
									id="fresh-additional information-user-profile"
									className="fresh-input css-yk16xz-control"
									onChange={this.onchangeAdditionalInformation}
									type="text"
									value={this.state.additional_Information}
								/>
							</div>

							<div className="fresh-field-wrapper">
								<label
									className="fresh-label"
									htmlFor="fresh-additional_notification_to"
								>
									<span className="fresh-title">
										<span className="reminder" />
										Additional Notification To
									</span>
								</label>

								<input
									style={{ cursor: 'text' }}
									id="fresh-additional_notification_to-user-profile"
									className="fresh-input css-yk16xz-control"
									onChange={this.onchangeAdditionalNotificationTo}
									type="text"
									value={this.state.additional_notification_to}
								/>
							</div>
						</div>
						{/* anas  */}
						<div>
							<Button variant="warning" className="mx-4" onClick={this.onSubmit} disabled={this.isFormSubmitted} >
								{this.isFormSubmitted && <Spinner animation="border" variant="dark" size="sm" />}
								{this.isFormSubmitted ? "In Progress..." : "Submit"}
							</Button>
							<Button variant="outline-danger" onClick={this.onCancel}>Cancel</Button>
						</div>
					</div>
					<ToastContainer
						position="top-right"
						autoClose={false}
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
					/>
				</div>
			</LoadingOverlay>
		);
	}
}

export default ReqForm;
