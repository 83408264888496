import React, { useEffect, useState } from 'react';
import './CQ.module.css';
// import CurrencyRow from './CurrencyRow'
import AxiosApi from '../../Api';

function Currencyconverter(props) {
  const [currencylist, setcurrencylist] = useState([])
  const [fromCurrency, setFromCurrency] = useState('USD')
  const [toCurrency, setToCurrency] = useState('USD')
  const [amountInFromCurrency, setAmountInFromCurrency] = useState()
  const [convertedvalue, setconvertedvalue] = useState()
  const ackno = props.ackno;

  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/complaint/api/currencies", { ackno: ackno }).then(response => {
      console.log(response.data);
      setcurrencylist(response.data);
    })
  }, [])
  /*
    useEffect(() => {
      if (fromCurrency != null && toCurrency != null) {
        fetch(`${BASE_URL}?base=${fromCurrency}&symbols=${toCurrency}`)
          .then(res => res.json())
          .then(data => setExchangeRate(data.rates[toCurrency]))
      }
    }, [fromCurrency, toCurrency])
    */

  const getconvertedval = () => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/complaint/api/currencyconverter", { from: fromCurrency, value: amountInFromCurrency, to: toCurrency, ackno: ackno }).then(response => {
      console.log(response.data);

      setconvertedvalue(response.data);
    })
  };

  return (
    <>
      <div id="curr-conv-modal" tabindex="-1" role="dialog" aria-labelledby="curr-conv-modal-title" >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="row gutters-xs">
                      <div className="col-8">
                        <input id="val-curr1" type="number" value={amountInFromCurrency} onChange={e => setAmountInFromCurrency(e.target.value)} className="form-control" placeholder="From" />
                      </div>
                      <div className="col-4">
                        <select id="select-curr1" value={fromCurrency} onChange={e => setFromCurrency(e.target.value)} className="form-control custom-select">
                          {currencylist.map((val) => {
                            return (
                              <option value={val.Currency}>{val.Currency}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <div className="row gutters-xs">
                      <div className="col-8">
                        <input id="val-curr2" type="number" value={convertedvalue} readOnly className="form-control" placeholder="To" />
                      </div>
                      <div className="col-4">
                        <select name="subtype" value={toCurrency} onChange={e => setToCurrency(e.target.value)} id="select-curr2" className="form-control custom-select">
                          {currencylist.map((val) => {
                            return (
                              <option value={val.Currency}>{val.Currency}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button id="btn-convert" type="button" onClick={getconvertedval} className="btn btn-primary">Convert</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Currencyconverter;