import React from "react";
import { Header, Portals, Footer } from "./";
import Navbar from './Navbar'
import "../assets/styles/App.css";
import { useNavigate, Navigate } from 'react-router-dom';
/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */

import { useIsAuthenticated } from "@azure/msal-react";

const Home = (props) => {
  const navigate = useNavigate();

  // const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated && !props.loggedInViaInput) {
    return <> <Navigate to="/login" />; </>
  }
  return (
    (isAuthenticated || props.loggedInViaInput) && (
      <>
        <Navbar />
        <div style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
          <Portals />
        </div>
      </>
    )

  );
};

export default Home;
