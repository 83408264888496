// navbar2 OOC
import React, { Component } from "react";
import logo from "../abi_logo.png";
import "../style.css";
import Navigation from "./Navigation";
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../../assets/images/profile.jpg';
import NavBar from "../../../components/Navbar";
export class Navbar_OOC extends Component {

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Navigation userRole={this.props.userRole} />
      </React.Fragment>
    );
  }
}

export default Navbar_OOC;
