import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';

const NotAuth = () => {
  const navigate = useNavigate();


  //useEffect(() => {
    //window.location.reload();
//  }, [])

  const goHome = () => {
    navigate('/');
  };

  return (
    <div style={styles.container}>
      <h2 className='text-uppercase'>Not Authorized</h2>
      <p className='text-capitalize'>You do not have permission to access this page or resource.</p>
      <Button variant="filled" color="green" onClick={() => window.location.reload()}>Please Refresh</Button>
      <p className='text-lg m-0 p-0'>OR</p>
      <Button variant="filled" onClick={goHome} color="yellow">Go to Home</Button>
    </div >
  );
};

const styles = {
  container: {
    textAlign: 'center',
    margin: '20px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heading: {
    color: '#ff0000', // Red color for emphasis
  },
  message: {
    marginBottom: '20px',
  },
  button: {
    padding: '10px',
    background: '#FFFF00', // Green color
    color: '#fff', // White text
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default NotAuth;