import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
//import AxiosApi from "AxiosApi";
import AxiosApi from '../../../Api'
import Select from "react-select";
import "../userRegistration.css";

const initialstate = {
    emailerror: "",
    role_error: "",
    soldto_error: ""
}
export class UserRegistration extends Component {

    constructor(props) {
        super(props);

        this.onchangeusername = this.onchangeusername.bind(this);
        this.onchangefirstname = this.onchangefirstname.bind(this);
        this.onchangelastname = this.onchangelastname.bind(this);
        this.onchangemailid = this.onchangemailid.bind(this);
        this.onchangusertype = this.onchangusertype.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.onchangeCustomerAddress = this.onchangeCustomerAddress.bind(this);
        this.onchangesoldto = this.onchangesoldto.bind(this);
        this.getdata = this.getdata.bind(this);
        this.getsoldtodata = this.getsoldtodata.bind(this);

        this.state = {

            soldtohide: "input_wrapper_userRegistration hide_data",
            username: "",
            firstname: "",
            lastname: "",
            emailid: "",
            password: "",
            usertype: "",
            customer_address: "",
            soldto: "",
            role: "Retpack",
            soldtoselected: "",
            usertype_selected: "",
            successful: false,
            message: "",
            parentAccountName: [],
            userId: "",
            soldTo: "",
            pwd: "",

            init: initialstate,
        };
    }

    componentDidMount() {

        this.getdata();
        this.getsoldtodata();
    }

    onchangusertype(e) {
        console.log("usertype" + e.label);
        this.setState({
            usertype_selected: e.label,
        });


        if (e.label == "Customer-External" || e.label == "Customer-InterCompany") {
            this.setState({
                soldtohide: "input_wrapper_userRegistration",
            });
        } else {
            this.setState({
                soldtohide: "input_wrapper_userRegistration hide_data",
            });
        }
    }

    onchangeusername(e) {
        console.log(e.target.value);
        this.setState({
            username: e.target.value,
        });
    }

    onchangefirstname(e) {
        console.log(e.target.value);
        this.setState({
            firstname: e.target.value,
        });
    }

    onchangelastname(e) {
        console.log(e.target.value);
        this.setState({
            lastname: e.target.value,
        });
    }

    onchangemailid(e) {
        console.log(e.target.value);
        this.setState({
            emailid: e.target.value,
        });
    }

    onchangeCustomerAddress(e) {
        console.log(e.target.value);
        this.setState({
            customer_address: e.target.value,
        });
    }

    onchangesoldto(e) {
        console.log("sold to" + e.label);
        this.setState({
            soldtoselected: e.label,
        });
    }

    getdata() {
        AxiosApi
            .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/registration")
            .then((res) => {
                this.setState({
                    usertype: res.data,
                });
            })
    }

    getsoldtodata() {
        AxiosApi
            .get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/getsoldto/")
            .then((res) => {
                console.log(res.data)
                this.setState({
                    soldto: res.data,
                });
            })
    }

    validation = () => {
        let emailerror = "";
        let role_error = "";
        let soldto_error = "";

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let isValid = true;

        if (!pattern.test(this.state.emailid)) {
            isValid = false;
            emailerror = "Please enter valid email address.";
        }

        if (!this.state.emailid) {
            emailerror = "Please enter emaild ";
        }
        console.log(this.state.usertype_selected.length)
        if (this.state.usertype_selected.length === 0) {
            role_error = "Please select roles";
        }

        if (this.state.usertype_selected == "Customer-External" || this.state.usertype_selected == "Customer-InterCompany") {
            console.log(this.state.soldtoselected.length)
            if (this.state.soldtoselected.length === 0) {
                soldto_error = "Please select soldto";
            }
        } else {
            soldto_error = "";
        }

        if (emailerror || role_error || soldto_error) {
            this.setState({
                emailerror,
                role_error,
                soldto_error
            });
            return false;
        } else {
            return true;
        }
    }

    generatecq(options) {
        const defaultOptions = {
            length: 36,
            lowercase: true,
            uppercase: true,
            numbers: true,
            symbols: false,
        };

        const mergedOptions = { ...defaultOptions, ...options };

        let characters = '';

        if (mergedOptions.lowercase) characters += 'abcdefghijklmnopqrstuvwxyz';
        if (mergedOptions.uppercase) characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (mergedOptions.numbers) characters += '0123456789';
        if (mergedOptions.symbols) characters += '!@#$%^&*()-_=+[]{}|;:\'",.<>?/';

        let result = '';
        const mandatoryTypes = [];

        if (mergedOptions.lowercase) {
            result += characters.charAt(Math.floor(Math.random() * 26));
            mandatoryTypes.push('lowercase');
        }

        if (mergedOptions.uppercase) {
            result += characters.charAt(26 + Math.floor(Math.random() * 26));
            mandatoryTypes.push('uppercase');
        }

        if (mergedOptions.numbers) {
            result += characters.charAt(52 + Math.floor(Math.random() * 10));
            mandatoryTypes.push('numbers');
        }

        if (mergedOptions.symbols) {
            result += characters.charAt(62 + Math.floor(Math.random() * 14));
            mandatoryTypes.push('symbols');
        }

        for (let i = mandatoryTypes.length; i < mergedOptions.length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        result = result.split('').sort(() => Math.random() - 0.5).join('');
        return result;
    }

    // pwd;
    generatePassword = () => {
        this.state.pwd = this.generatecq({
            length: 36,
            lowercase: true,
            uppercase: true,
            numbers: true,
            symbols: false,
        });
        // setPassword(pwd);
        // console.log(pwd, 'password');
    };

    onsubmit = async (e) => {
 
        const isValid = this.validation();
        if (isValid) {

            console.log(this.state.emailid + " ,," + this.state.usertype_selected)
            try {
                this.generatePassword();

                const response = await AxiosApi.post(
                    process.env.REACT_APP_API_ROOT_URL_PREFIX + '/api/auth/signup',
                    {
                        username: this.state.username, emailid: this.state.emailid, roles: this.state.usertype_selected, soldto: this.state.soldtoselected , pwd:this.state.pwd
                    },
                );

                // console.log(response.data);
                console.log(response);
                //console.log(response.data.rows[0]);
                toast.success("User successfully registered");
                setTimeout(() => window.reload('/Registration'), 3000);

              await  AxiosApi
                    .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/createUser`, { email: this.state.emailid, role: this.state.role })
                    .then(async(response) => {
                        console.log('user created', this.state.emailid);

                        return await AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${this.state.emailid}`);
                    })
                    .then(async(response) => {
                        this.state.userId = response?.data?.recordset[0]?.userID;
                        return await AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchRoleId?role=${this.state.role}`);
                    })
                    .then(async(response) => {
                        this.state.roleId = response.data?.recordset[0]?.roleID;
                      
                        return await AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addLoginMaster`, {
                            userId: this.state.userId,
                            email: this.state.emailid,
                            password: this.state.pwd,
                            computerized: 1,
                            role: this.state.role
                        });
                    })
                    .then(async(response) => {
                       await AxiosApi
                            .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addUserSoldToMapping`, { userId: this.state.userId, soldTo: this.state.soldtoselected, parentAccountName: this.state.soldtoselected })
                            .then((response) => {
                                console.log(response, 'email');
                                // setSuccessData(response?.data?.rowsAffected[0] == 1 ? true : false);
                            });
                        return await AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/retpackEmail`, { email: this.state.emailid, password: this.state.pwd });
                    })
                    .then((response) => {
                        console.log(response);
                    });









                // const response = await AxiosApi.post(
                //     process.env.REACT_APP_API_ROOT_URL_PREFIX + '/api/auth/signup',
                //     {
                //         username: this.state.username, emailid: this.state.emailid, roles: this.state.usertype_selected, soldto: this.state.soldtoselected
                //     },
                // );

                // // console.log(response.data);
                // console.log(response);
                // //console.log(response.data.rows[0]);
                // toast.success("User successfully registered");
                // setTimeout(() => window.reload('/Registration'), 3000);
                //     if (response.data === 'recovery email sent') {
                //     this.setState({
                //         showError: false,
                //         messageFromServer: 'recovery email sent',
                //         showNullError: false,
                //     });

                // }
            } catch (error) {
                console.log(error.response);
                if (error.response.data.message === 'Failed! Username is already in use!') {
                    toast.warning("Failed! Username is already in use!");
                } else if (error.response.data.message === 'Failed! Email is already in use!') {
                    toast.warning("Failed! Email is already in use!");
                }
            }
        }
    }

    render() {
        return (

            <div className="container my-3 box_wrapper">
                <div className="userRegistration_box">
                    <h4>Registration Form</h4>
                    <div className="input_wrapper_userRegistration">
                        <label className="fresh-label" for="fresh-request_type">
                            <span className="fresh-title">
                                <span className="reminder"> </span> First Name
                            </span>
                        </label>
                        <input
                            id="fresh-first-name"
                            className="userRegistration_input"
                            onChange={this.onchangefirstname}
                            required={true}
                            type="text"
                            value={this.state.firstname}
                        ></input>
                    </div>
                    <div className="input_wrapper_userRegistration">
                        <label className="fresh-label" for="fresh-request_type">
                            <span className="fresh-title">
                                <span className="reminder"> </span> Last Name
                            </span>
                        </label>
                        <input
                            id="fresh-last-name"
                            className="userRegistration_input"
                            onChange={this.onchangelastname}
                            required={true}
                            type="text"
                            value={this.state.lastname}
                        ></input>
                    </div>
                    <div className="input_wrapper_userRegistration">
                        <label className="fresh-label" for="fresh-request_type">
                            <span className="fresh-title">
                                <span className="reminder"> </span> Customer Name (as per ABI)
                            </span>
                        </label>
                        <input
                            id="fresh-user-name"
                            className="userRegistration_input"
                            onChange={this.onchangeusername}
                            required={true}
                            type="text"
                            value={this.state.username}
                        ></input>
                    </div>

                    <div className="input_wrapper_userRegistration">
                        <label className="fresh-label" for="fresh-request_type">
                            <span className="fresh-title">
                                <span className="reminder"> * </span> Email Id
                            </span>
                        </label>
                        <input
                            id="fresh-email-id"
                            className="userRegistration_input"
                            onChange={this.onchangemailid}
                            required={true}
                            type="text"
                            value={this.state.emailid}
                        ></input>
                    </div>
                    <div style={{ fontSize: 12, color: "red" }}> {this.state.emailerror} </div>
                    <br />

                    <div className={this.state.soldtohide}>
                        <div className="input_wrapper_userRegistration">
                            <label className="fresh-label" for="fresh-request_type">
                                <span className="fresh-title">
                                    <span className="reminder"> * </span> Sold To
                                </span>
                            </label>
                            <Select options={this.state.soldto} onChange={this.onchangesoldto} />
                        </div>
                    </div>
                    <div style={{ fontSize: 12, color: "red" }}> {this.state.soldto_error} </div>
                    <div className="input_wrapper_userRegistration">
                        <label className="fresh-label" for="fresh-request_type">
                            <span className="fresh-title">
                                <span className="reminder"> * </span> Roles
                            </span>
                        </label>
                        <Select options={this.state.usertype} onChange={this.onchangusertype} />
                    </div>
                    <div style={{ fontSize: 12, color: "red" }}> {this.state.role_error} </div>
                    <div >
                        <button
                            className="userRegistration_button"
                            onClick={this.onsubmit}
                        >
                            Register
                        </button>
                    </div>
                </div>
                <ToastContainer autoClose={3000}></ToastContainer>
            </div>
        )
    }
}

export default UserRegistration;