import React, { useEffect, useState } from 'react';
//import AxiosApi from 'AxiosApi';
import AxiosApi from '../../Api'
import ConfirmAlert from './common/ConfirmAlert';
import MultipleValueTextInput from 'react-multivalue-text-input';
// import { set } from '../../../../routes/rbac/createUser';
import { Modal } from 'react-bootstrap';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import './styles/Signup.css';
import { roles } from '../../Constants';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const Signup = () => {
  const navigate = useNavigate();
  const [soldTo, setSoldTo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [parentAccountName, setParentAccountName] = useState('');
  const [soldToList, setSoldToList] = useState([]);
  const [parentAccountNameError, setParentAccountNameError] = useState([]);
  const [soldToError, setSoldToError] = useState([]);
  const [soldToVal, setSoldToVal] = useState("Parent Account Number");
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [role, setRole] = useState('Internal_Customer');
  const [isMailOrSpoc, setIsMailOrSpoc] = useState("Email");
  const [spocValidation, setSpocValidation] = useState(false);
  const [successData, setSuccessData] = useState();
  //const [selectedRole, setSelectedRole] = useState('');
  const checkSoldTo = (value) => {
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getSoldTo?soldTo=${value}`).then((response) => {
      if (response?.data?.recordset[0]?.SoldTo == value) setSoldToError('Valid Sold To Number');
      else setSoldToError('Invalid Sold To');
    });
  };

  const checkParentAccountname = (value) => {
    let value1 = value.replace(/&/g, "%26");
    console.log(value1);
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/getParentAccountName?parentAccountName=${value1}`).then((response) => {
      if (response?.data?.recordset?.length > 0) setParentAccountNameError('Valid Parent Account Name');
      else setParentAccountNameError('Invalid Parent Account Name');
    });
  };


  // const [validEmailOrSpocText, setValidEmailOrSpocText] = useState('Email')
  function generate(options) {
    const defaultOptions = {
      length: 36,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    let characters = '';

    if (mergedOptions.lowercase) characters += 'abcdefghijklmnopqrstuvwxyz';
    if (mergedOptions.uppercase) characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (mergedOptions.numbers) characters += '0123456789';
    if (mergedOptions.symbols) characters += '!@#$%^&*()-_=+[]{}|;:\'",.<>?/';

    let result = '';
    const mandatoryTypes = [];

    if (mergedOptions.lowercase) {
      result += characters.charAt(Math.floor(Math.random() * 26));
      mandatoryTypes.push('lowercase');
    }

    if (mergedOptions.uppercase) {
      result += characters.charAt(26 + Math.floor(Math.random() * 26));
      mandatoryTypes.push('uppercase');
    }

    if (mergedOptions.numbers) {
      result += characters.charAt(52 + Math.floor(Math.random() * 10));
      mandatoryTypes.push('numbers');
    }

    if (mergedOptions.symbols) {
      result += characters.charAt(62 + Math.floor(Math.random() * 14));
      mandatoryTypes.push('symbols');
    }

    for (let i = mandatoryTypes.length; i < mergedOptions.length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    result = result.split('').sort(() => Math.random() - 0.5).join('');
    return result;
  }

  var pwd;
  const generatePassword = () => {
    pwd = generate({
      length: 36,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    });
    setPassword(pwd);
    // console.log(pwd, 'password');
  };

  var userId, roleId;
  const isValidEmail = (value) => {
    var validRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex)) {
      setEmailError('Valid Email');
    } else setEmailError('Invalid Email');
  };

  // var key= [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  var hashed;
  // start of submit fxn
  const onSubmit = (e) => {
    e.preventDefault();
    // db main request jayegi create account ki
    //random passowrd generate hoga
    generatePassword();

    // var textBytes = aesjs.utils.utf8.toBytes(hashed);
    // var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    // var encryptedBytes = aesCtr.encrypt(textBytes);
    // var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    AxiosApi
      .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/createUser`, { email: email, role: role })
      .then((response) => {
        console.log('user created', email);

        return AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${email}`);
      })
      .then((response) => {
        userId = response?.data?.recordset[0]?.userID;
        return AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchRoleId?role=${role}`);
      })
      .then((response) => {
        roleId = response.data?.recordset[0]?.roleID;
        return AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addLoginMaster`, {
          userId: userId,
          email: email,
          password: pwd,
          computerized: 1,
          role: role
        });
      })
      .then((response) => {
        AxiosApi
          .post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addUserSoldToMapping`, { userId: userId, soldTo: soldTo, parentAccountName: parentAccountName })
          .then((response) => {
            console.log(response, 'email');
            setSuccessData(response?.data?.rowsAffected[0] == 1 ? true : false);
          });
        return AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/sendEmail`, { email: email, password: pwd });
      })
      .then((response) => {
        console.log(response);
      });
  };

  // end of submit fxn
  async function hideAlert() {
    window.location.reload();
  }
  const isDuplicateEmail = (value) => {
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/isDuplicate?email=${value}`).then((response) => {
      if (response?.data?.recordset?.length >= 1) {
        setIsDuplicate(true);
      } else setIsDuplicate(false);
    });
  };

  {/*const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setSelectedRole(selectedRole);
  
    if (selectedRole == 'ABI_CQ_SPOC' || selectedRole == 'CX_Agent') {
      setSpocValidation(true);
      setIsMailOrSpoc('SPOC');
      document.getElementById('hideDiv').style.display = 'none';
      document.getElementById('hideSoldTo').style.display = 'none';
    } else if (selectedRole == 'External_Customer') {
      setSpocValidation(false);
      setSoldToVal('Sold To');
      document.getElementById('hideDiv').style.display = 'none';
      document.getElementById('hideSoldTo').style.display = 'flex';
    } else {
      setSpocValidation(false);
      setSoldToVal('Parent Account Number');
      document.getElementById('hideDiv').style.display = 'flex';
      document.getElementById('hideSoldTo').style.display = 'flex';
    }
  };
  
  const updateUserRole = () => {
    if (!selectedRole || !email) {
      console.error('Please select a role and enter an email address.');
      return;
    }
    AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/UpdateRoles`, {
      email: email,
      newRole: selectedRole,
    })
    .then((response) => {
      toast.success('User role updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log('User role updated successfully', response);
    })
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        toast.error('Failed to update role.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error('Error updating user role!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.error('Error updating user role', error);
    });
  };
  */}
  
  const hideDiv = (value) => {

    if (value == 'ABI_CQ_SPOC') {
      // alert('selected')
      setSpocValidation(true);
      setIsMailOrSpoc("SPOC")
      document.getElementById('hideDiv').style.display = "none";
      console.log('all done!');
      if (soldToVal == 'Parent Account Number' || soldToVal == 'Sold To') {
        document.getElementById('hideSoldTo').style.display = "none";

      }
    }

    else if (value == 'External_Customer') {
      setSoldToVal("Sold To");
      setIsMailOrSpoc("Email");
      document.getElementById('hideDiv').style.display = "none";
      // document.getElementById('hideSoldTo').style.display = "block";
      document.getElementById('hideSoldTo').style.display = "flex";
      setSpocValidation(false);
    }
    else {
      setSoldToVal("Parent Account Number");
      setIsMailOrSpoc("Email");
      setSpocValidation(false);
      document.getElementById('hideDiv').style.display = 'flex';
      document.getElementById('hideSoldTo').style.display = "flex";
    }
  }

  if (
    (roles.username)?.length == 0 ||
    ((roles.role)?.length > 0 && ((roles.role) !== 'ABI_CQTeam' && (roles.role) !== 'CX_Agent'))
  ) {
    return (
      <>
        <p>You are not Authorized to view this page</p>
      </>
    );
  }
  function submitValidate() {

    if (!role?.length > 0 || emailError == 'Invalid Email') {

      return true;
    }
    if (role == 'Internal_Customer') {
      console.log('2 if')
      if (parentAccountNameError != 'Valid Parent Account Name') {
 
        return true;
      }
    }
    if (role == 'External_Customer') {
      console.log('3 if')
      if (soldToError != 'Valid Sold To Number') {

        return true;
      }
    }

    return false;
  }

  {/*function submitRoleValidate() {

    if (!selectedRole?.length > 0 || emailError == 'Invalid Email') {

      return true;
    }
    if (selectedRole == 'Internal_Customer') {
      console.log('2 if')
      if (parentAccountNameError != 'Valid Parent Account Name') {
 
        return true;
      }
    }
    if (selectedRole == 'External_Customer') {
      console.log('3 if')
      if (soldToError != 'Valid Sold To Number') {

        return true;
      }
    }

    return false;
  }
    */}
  return (
    <>
      <div className="container signup_box">


        <form onsubmit="event.preventDefault()" id="form">
          <h3>Register a User</h3>
  
          <div className="row form_card">
            <div className="col-md-6 my-2 ">
              <p className="form-control-label form_label">
                Role
                <span className="text-danger">*</span>
              </p>
              <select
                className="form-control"
                name="email"
                placeholder=""
                onChange={(e) => {
                  setRole(e.target.value);
                  hideDiv(e.target.value);
                  // setEmailValidation(e.target.value)
                }}
                onblur="validate(3)"
              >
                <option value="">Select Role</option>
                <option value="External_Customer">External Customer</option>
                <option value="Internal_Customer" selected="selected">Internal Customer</option>
                <option value="ABI_CQ_SPOC" >ABI SPOC</option>
              </select>
            </div>
          </div>
          
          <div className="row form_card">
            <div className="col-md-6 my-2 ">

              <p className="form-control-label form_label">
                {isMailOrSpoc}
                {/* this is dynamic email or spoc created by Anas  */}
                <span className="text-danger"> *</span>
                {!isDuplicate && emailError === `Invalid ${isMailOrSpoc}` ? (
                  <font style={{ fontSize: '12px', color: 'red ' }}>{emailError}</font>
                ) : null}
                {!isDuplicate && emailError === `Valid ${isMailOrSpoc}` ? (
                  <font style={{ fontSize: '12px', color: 'green' }}>{emailError}</font>
                ) : null}
                {isDuplicate ? (
                  <font style={{ fontSize: '12px', color: 'red ' }}>
                    {`${isMailOrSpoc} already registered`}
                  </font>
                ) : null}
              </p>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (spocValidation == false) {
                    isValidEmail(e.target.value);
                  }
                  isDuplicateEmail(e.target.value);
                }}
                onblur="validate(3)"
              />
            </div>
          {/*</div>
          {isDuplicate && (
          <div className="row form_card">
            <div className="col-md-6 my-2">
              <p className="form-control-label form_label">
                Select New Role <span className="text-danger">*</span>
              </p>
              <select
                className="form-control"
                value={selectedRole}
                onChange={handleRoleChange}
              >
                <option value="">Select Role</option>
                <option value="External_Customer">External Customer</option>
                <option value="Internal_Customer">Internal Customer</option>
                <option value="ABI_CQ_SPOC">ABI SPOC</option>
                <option value="CX_Agent">CX Agent</option>
              </select>
            </div>
          </div>
        )}
          */}
          {/* start of sold to */}
          <div className="row form_card" id="hideSoldTo" >
            <div className="col-md-6 my-2 ">
              <p className="form-control-label form_label">
                {soldToVal}<span className="text-danger"> *</span>
                {soldToError === 'Invalid Sold To' ? (
                  <font style={{ fontSize: '12px', color: 'red ' }}>{soldToError}</font>
                ) : (
                  <font style={{ fontSize: '12px', color: 'green' }}>{soldToError}</font>
                )}
              </p>
              {/* <MultipleValueTextInput
                      onItemAdded={(item, allItems) => {
                        console.log(soldTo);
                        checkSoldTo(item);
                        setSoldTo((soldTo) => [...soldTo, item]);
                      }}
                      onItemDeleted={(item, allItems) => {console.log(soldTo);
                      setSoldTo((soldTo) => soldTo?.filter((i) => i !== item))}}            
                       /> */}
              <input

                type="text"
                id="mob"
                name="mob"
                placeholder=""
                onChange={(e) => {
                  setSoldTo(e.target.value);
                  checkSoldTo(e.target.value);
                }}
              />

            </div>
          </div>

          {/* end of sold to */}
          <div className="row form_card" id="hideDiv">
            <div className="col-md-6 my-2 ">
              <p className="form-control-label form_label">
                Parent Account Name
                <span className="text-danger"> *</span>
                {parentAccountNameError === 'Invalid Parent Account Name' ? (
                  <font style={{ fontSize: '12px', color: 'red ' }}>{parentAccountNameError}</font>
                ) : (
                  <font style={{ fontSize: '12px', color: 'green' }}>{parentAccountNameError}</font>
                )}
              </p>
              <input
                type="text"
                id="mob"
                name="mob"
                placeholder=""
                onChange={(e) => {
                  setParentAccountName(e.target.value);
                  checkParentAccountname(e.target.value);
                }}
              />
            </div>

          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="filled" color="green" radius="md" className='mx-2'
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
            <Button variant="filled" color="green" radius="md"
              onClick={onSubmit}
              disabled={submitValidate()}
            >
              Create Account
            </Button>
          </div>
          {/*
            <div>
            <Button variant="filled" color="green" radius="md"
              onClick={updateUserRole}
              disabled={submitRoleValidate()}
              >
                Update role
                </Button>
                <ToastContainer />
                </div>
                */}
                </div>
        </form>

      </div >
      <Modal show={successData} onHide={hideAlert}>
        <Modal.Header closeButton>
          <Modal.Title>{successData === true ? 'Created' : 'Failed'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successData === true
            ? 'User Created Successfully\n Please check your mail for password'
            : `${successData}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="filled" color="green" radius="md" onClick={hideAlert}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Signup;