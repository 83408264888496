import React, { Component } from "react";
import "../style.css";
import "../reqform.css"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Select from "react-select";
//import AxiosApi from "AxiosApi";
import AxiosApi from '../../../Api'
//import { isNum } from "react-toastify/dist/utils";
import { IconFileTypeCsv } from '@tabler/icons-react';
import { roles } from "../../../Constants";
import { Button } from '@mantine/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter } from 'react-bootstrap-table2-filter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './reportDashboard.css';
export class reportDashboard extends Component {
  constructor(props) {
    super(props);

    this.onChangeReportType = this.onChangeReportType.bind(this);
    this.onchangeLocation = this.onchangeLocation.bind(this);
    this.onchangePlant = this.onchangePlant.bind(this);
    this.onchangeCustomerName = this.onchangeCustomerName.bind(this);
    this.onchangeSoldTo = this.onchangeSoldTo.bind(this);
    this.onchangeContainerNum = this.onchangeContainerNum.bind(this);
    this.onchangeShipmentRef = this.onchangeShipmentRef.bind(this);
    this.onchangePONum = this.onchangePONum.bind(this);
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmit1 = this.onSubmit1.bind(this);
    this.onchangeetaetd = this.onchangeetaetd.bind(this);
    this.onchangeshipingline = this.onchangeshipingline.bind(this);
    this.getrolebaseddropdown = this.getrolebaseddropdown.bind(this);

    this.state = {
      show_openempties: "report-form parameters hide_data ",
      show_openempties_report: "Dashboard_report hide_data ",
      show_costanalysis: "report-form parameters hide_data ",
      show_costanalysis_report: "Dashboard_report hide_data",
      location: "",
      plant: "",
      customer_name: "",
      sold_to: "",
      container_no: "",
      shipment_ref: "",
      po_num: "",
      etaetd: "",
      shipingline: "",
      filesData: [],
      filesData1: [],
      emailid: JSON.stringify((roles.emailid)).replace(/['"]+/g, ''),
      userRole: JSON.stringify((roles.userRole)).replace(/['"]+/g, ''),
      typeSelect: [],
    }
  };

  componentDidMount() {
    // console.log(this.props.emailid);

    console.log(this.state.userRole);
    console.log({ emailid: this.state.emailid });
    // this.getcustomeraddressdata({emailid:this.props.emailid});
    var localuserRole = JSON.stringify((roles.userRole)).replace(/['"]+/g, '');

    console.log("localuerrole =====" + localuserRole);

    this.setState({
      userRole: localuserRole,
    });

    console.log("propsroe =====" + this.state.userRole);

    var locastateemail = JSON.stringify((roles.emailid)).replace(/['"]+/g, '');
    console.log(locastateemail);
    console.log(locastateemail.replace(/['"]+/g, ''));
    if (localuserRole == 2 || localuserRole == 4) {
      this.getcustomernamedata({ emailid: this.state.emailid })
    };
    this.getrolebaseddropdown();

  }


  getcustomernamedata(emailid) {
    console.log(emailid)
    AxiosApi
      .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/filterDataret/customername", emailid)
      .then((res) => {
        // console.log(res.data[0].label)
        console.log(res.data)
        this.setState({
          customer_name: res.data[0].label,
        });
      })
  }

  getrolebaseddropdown() {
    console.log(this.state.userRole)
    if (this.state.userRole == 1 || this.state.userRole == 3 || this.state.userRole == 5) {
      this.setState({
        typeSelect: [{ label: "Open Empties Return", value: 1 }, { label: "Cost Analysis - Predicted", value: 2 }]
      });
    } else {
      this.setState({
        typeSelect: [{ label: "Open Empties Return", value: 1 }]
      });

    }
  }

  onChangeReportType(e) {
    console.log(e.value);

    if (e.value == 1) {
      this.setState({
        show_openempties: "report-form parameters",
        show_openempties_report: "Dashboard_report",
        show_costanalysis: "report-form parameters hide_data",
        show_costanalysis_report: "Dashboard_report hide_data",
      })
    } else if (e.value == 2) {
      this.setState({
        show_costanalysis: "report-form parameters",
        show_costanalysis_report: "Dashboard_report",
        show_openempties: "report-form parameters hide_data",
        show_openempties_report: "Dashboard_report hide_data",
      })
    }
    else {
      this.setState({
        show_openempties: "report-form parameters hide_data",
        show_openempties_report: "Dashboard_report hide_data",
        show_costanalysis: "report-form parameters hide_data",
        show_costanalysis_report: "Dashboard_report hide_data",
      });
    }
  }

  onchangeshipingline(e) {
    console.log(e.target.value)
    this.setState({
      shipingline: e.target.value,
    })
  }

  onchangeLocation(e) {
    console.log(e.target.value);
    this.setState({
      location: e.target.value,
    });
  }
  onchangePlant(e) {
    console.log(e.target.value);
    this.setState({
      plant: e.target.value,
    });
  }

  onchangeContainerNum(e) {
    console.log(e.target.value);
    this.setState({
      container_no: e.target.value,
    });
  }

  onchangeCustomerName(e) {
    console.log(e.target.value);
    this.setState({
      customer_name: e.target.value,
    });
  }

  onchangeSoldTo(e) {
    console.log(e.target.value); {
      this.setState({
        sold_to: e.target.value,
      });
    }
  }

  onchangeShipmentRef(e) {
    console.log(e.target.value);
    this.setState({
      shipment_ref: e.target.value,
    });
  }
  onchangePONum(e) {
    console.log(e.target.value);
    this.setState({
      po_num: e.target.value,
    });
  }


  onchangeetaetd(e) {
    console.log(e.target.value);
    this.setState({
      etaetd: e.target.value,
    })
  }

  onSubmit() {
    console.log(this.state);
    AxiosApi
      .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getOpenEmpties", this.state)
      .then((res) => {
        toast.success("Data extracted");
        // then print response status
        console.log(res);
        this.setState({
          filesData: res.data,
        });

      })
      .catch((err) => {
        // then print response status
        console.log(err);
        toast.error("Error in generating report.");
      });
  }

  onSubmit1() {
    console.log(this.state);
    AxiosApi
      .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getCostAnalysis", this.state)
      .then((res) => {
        toast.success("Data extracted");
        // then print response status
        console.log(res);
        this.setState({
          filesData: res.data,
        });

      })
      .catch((err) => {
        // then print response status
        console.log(err);
        toast.error("Error in generating report.");
      });
  }



  render() {
    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div className="div_export">
          <Button
            leftSection={<IconFileTypeCsv size={14} />}
            variant="filled" color="green"
            onClick={handleClick}>
            Export to CSV
          </Button>
        </div>
      );
    };


    // const typeSelect = [
    //   { label: "Open Empties Return", value: 1 },
    //   { label: "Cost Analysis - Predicted", value: 2 },
    // ];

    const columns_OpenEmpties = [
      {
        dataField: "id",
        text: "Sl No.",
        filter: textFilter(),
      },
      {
        dataField: "ERP Order No",
        text: "ERP Order No",
        filter: textFilter(),
      },
      {
        dataField: "Sold To",
        text: "Sold To",
        filter: textFilter(),
      },
      {
        dataField: "Customer Name",
        text: "Customer Name",
        filter: textFilter(),
      },
      {
        dataField: "Container Number/Truck",
        text: "Container Number/Truck",
        filter: textFilter(),
      },
      {
        dataField: "Plant",
        text: "Plant",
        filter: textFilter(),
      },
      {
        dataField: "Budnet number",
        text: "Budnet number (Only for US)",
        filter: textFilter(),
      },
      {
        dataField: "Shipment Reference",
        text: "Shipment Reference",
        filter: textFilter(),
      },
      {
        dataField: "ETA/ETD",
        text: "ETA/ETD",
        filter: textFilter(),
      },
      {
        dataField: "In",
        text: "In",
        filter: textFilter(),
      },
      {
        dataField: "Unloading Date",
        text: "Unloading Date",
        filter: textFilter(),
      },
      {
        dataField: "Credit Memo Number",
        text: "Credit Memo Number (Only for US)",
        filter: textFilter(),
      },
      {
        dataField: "CN Number",
        text: "CN Number (Not applicable for US)",
        filter: textFilter(),
      },
    ];

    const columns_getCostAnalysis = [
      {
        dataField: "id",
        text: "Sl No.",
        filter: textFilter(),
      },
      {
        dataField: "Location",
        text: "Location",
        filter: textFilter(),
      },
      {
        dataField: "Origin",
        text: "Origin",
        filter: textFilter(),
      },
      {
        dataField: "CustomerName",
        text: "Customer Name",
        filter: textFilter(),
      },
      {
        dataField: "Container Number/Truck",
        text: "Container Number/Truck",
        filter: textFilter(),
      },
      {
        dataField: "Plant",
        text: "Plant",
        filter: textFilter(),
      },
      {
        dataField: "Shipping Line",
        text: "Shipping Line",
        filter: textFilter(),
      },
      {
        dataField: "Demurrage Costs",
        text: "Demurrage Costs",
        filter: textFilter(),
      },
      {
        dataField: "Detention Costs",
        text: "Detention Costs",
        filter: textFilter(),
      },
      {
        dataField: "Unloading Date",
        text: "Unloading Date",
        filter: textFilter(),
      },
      {
        dataField: "[Storage_Costs]",
        text: "Storage Costs",
        filter: textFilter(),
      },
    ];

    const checkRole = this.state.userRole;

    return (
      <div>

        <div className="d-flex justify-content-center align-items-center px-5 my-4">
          <p className="m-0 p-0">Select Report Type </p>
          <Select options={this.state.typeSelect} onChange={this.onChangeReportType} className="w-25" />
        </div>

        <div className={this.state.show_openempties}>
          <h5>Report parameters:</h5>
          <div className="report_parameter-wrapper">
            <label className="report-label">Location</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeLocation}
                type="text"
                value={this.state.location}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">Customer Name</label>
            <div>
              {checkRole == 1 || checkRole == 3 || checkRole == 5 ?
                <input
                  className="retpack_input form-control no-border"
                  onChange={this.onchangeCustomerName}
                  type="text"
                  readOnly={false}
                  value={this.state.customer_name}
                ></input> :
                <input
                  className="retpack_input form-control no-border"
                  onChange={this.onchangeCustomerName}
                  type="text"
                  readOnly={true}
                  value={this.state.customer_name}
                ></input>}
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">Customer Sold-To</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeSoldTo}
                type="text"
                value={this.state.sold_to}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">Container No</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeContainerNum}
                type="text"
                value={this.state.container_no}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">Shipment Reference</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeShipmentRef}
                type="text"
                value={this.state.shipment_ref}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">PO Number</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangePONum}
                type="text"
                value={this.state.po_num}
              ></input>
            </div>
          </div>

          <Button variant="filled" color="yellow" onClick={this.onSubmit}>
            Submit
          </Button>

        </div>
        <div className={this.state.show_openempties_report}>
          <ToolkitProvider
            data={this.state.filesData}
            keyField="Sl No."
            columns={columns_OpenEmpties}
            exportCSV={{
              fileName: "OpenEmptiesReturn.csv",
              exportAll: true,
            }}
          >{
              props => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <BootstrapTable
                    hover
                    bootstrap4
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    classes="table-responsive"
                    wrapperClasses="table-wrapper"
                    headerClasses="table-header"
                    bodyClasses="table-body"


                    {...props.baseProps} />
                </div>
              )
            }</ToolkitProvider>
          <ToastContainer />
        </div>

        {/* ------------------------Cost analysis------------------------------- */}

        <div className={this.state.show_costanalysis}>
          <h5>Report parameters:</h5>
          <div className="report_parameter-wrapper">
            <label className="report-label"> Plant </label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangePlant}
                type="text"
                value={this.state.plant}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input"> Location </label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeLocation}
                type="text"
                value={this.state.location}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input"> ETA/ETD </label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeetaetd}
                type="text"
                value={this.state.etaetd}
              ></input>
            </div>
          </div>
          <div className="report_parameter-wrapper">
            <label className="report-label" for="input"> Customer Name </label>
            <div>
              {checkRole == 1 || checkRole == 3 || checkRole == 5 ?
                <input
                  className="retpack_input form-control no-border"
                  onChange={this.onchangeCustomerName}
                  type="text"
                  readOnly={false}
                  value={this.state.customer_name}
                ></input> :
                <input
                  className="retpack_input form-control no-border"
                  onChange={this.onchangeCustomerName}
                  type="text"
                  readOnly={true}
                  value={this.state.customer_name}
                ></input>}
            </div>
          </div>

          <div className="report_parameter-wrapper">
            <label className="report-label" for="input">Shipment Line</label>
            <div>
              <input
                className="retpack_input form-control no-border"
                onChange={this.onchangeshipingline}
                type="text"
                value={this.state.shipingline}
              ></input>
            </div>
          </div>

          <Button variant="filled" color="yellow" onClick={this.onSubmit1}>
            Submit
          </Button>


        </div>


        <div className={this.state.show_costanalysis_report}>
          <ToolkitProvider


            data={this.state.filesData}
            keyField="Sl No."
            columns={columns_getCostAnalysis}
            exportCSV={{
              fileName: "Cost Analysis.csv",
              exportAll: true,
            }}
          >{
              props => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <BootstrapTable
                    hover
                    bootstrap4
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    classes="table-responsive"
                    wrapperClasses="table-wrapper"
                    headerClasses="table-header"
                    bodyClasses="table-body"


                    {...props.baseProps} />
                </div>
              )
            }</ToolkitProvider>
          <ToastContainer />

        </div>
      </div>
    )
  };
}
export default reportDashboard;  