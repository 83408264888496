import { Navbar, Nav } from 'react-bootstrap';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "../style.css";
import { roles } from '../../../Constants';

export default class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: this.props.userRole || JSON.stringify((roles.userRole)).replace(/['"]+/g, ''),
            emailid: this.props.emailid || JSON.stringify((roles.emailid)).replace(/['"]+/g, ''),
            isAuthenticate: this.props.isAuthenticate || JSON.stringify((roles.isAuthenticate)).replace(/['"]+/g, ''),
        };
    }


    componentDidMount() {
        var role = JSON.stringify((roles.userRole)).replace(/['"]+/g, '');

        if (this.state.userRole == 'null') {
            this.setState({ userRole: role })
        } else if (this.state.userRole == '') {
            this.setState({ userRole: role })
        } else {
            this.setState({ userRole: role })
        }
        console.log(this.state.userRole);
        console.log(role);
    }

    render() {
        const role = this.state.userRole;
        return (
            <Navbar collapseOnSelect expand='md' className="color-nav">
                <Navbar.Toggle aria-controls='rsponsive-navbar-nav' />
                <Navbar.Collapse  id='responsive-navbar-nav'>
                    <Nav className='color-font'>
                        <Nav.Link >
                            <Link className="linkText" to='/retPack'>Home</Link></Nav.Link>
                        {(role == 1 || role == 3) &&
                            <Nav.Link >
                                <Link className="linkText" to='/retPack/dashboard'>Dashboard</Link></Nav.Link>}
                        <Nav.Link >
                            <Link className="linkText" to='/retPack/Reports'>Reports</Link></Nav.Link>
                        {(role == 1 || role == 3) &&
                            <Nav.Link >
                                <Link className="linkText" to='/Registration'>User Registration</Link></Nav.Link>}
                        <Nav.Link>
                            <Link className="linkText" to='/retPack/Requestform'>Request Form</Link></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

