// navbar3 cq portal navbar
import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import dataService from '../../services/dataService';
import defaultProfilePhoto from '../../assets/images/profile.jpg';
import { loginRequest } from '../../utils/authConfig';
import FeatherIcon from 'feather-icons-react';
import INT_Logo from '../../assets/images/Dark_Backgrounds.png';
import AxiosApi from '../../Api';
import * as ReactBootstrap from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown, Form, FormControl, Image } from 'react-bootstrap';
import { IconBell } from '@tabler/icons-react';
import {roles,updateRoles} from '../../Constants'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import logo from './images/abi_logo.png';
import navbaricon from './images/navbar-icon.jpg';
import { FaFileMedical } from 'react-icons/fa';
import { AiFillFileAdd } from 'react-icons/ai';
import { IoDocumentsSharp } from 'react-icons/io5';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { Navigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import Logo from '../../assets/images/abi_logo.png';
import snowChristmas from '../../assets/images/SnowFallGif.gif'
import { NavLink } from 'react-router-dom';

import Notification from '../../common/Notification';
import './Navbar.css';
import CommonNavbar from '../../common/Navbar/CommonNavbar';
import { Indicator, Loader, Avatar, Badge, Button } from '@mantine/core';

const NavBar = () => {
  var allnotifications = `<a href = '/notificationspage'>All Notifications</a>`;
  const [expanded, setExpanded] = useState(false);
  const [searchparam, setsearchparam] = useState('Acknowledgement No.');
  const [searchval, setsearchval] = useState();
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const { accounts, instance } = useMsal();
  const [data, setData] = useState([]);
  const [notificationLoader, setNotificationLoader] = useState(true);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const handleModalToggle = () => { setShowModal(!showModal); };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    <Navigate to='/' />;
  };
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };
  const [roleAssigned, setRoleAssigned] = useState();

  async function fetchNotiData() {
    await AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/notifications/cqnotifications')
      .then(function (response) {
        // handle success
        // console.log("🚀 ~ response:", response.data);
        setData(response.data);
        setNotificationLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log("🚀 ~ fetchNotiData ~ error:", error);
        setNotificationLoader(true);
      });
  }


  useEffect(() => {
    if (accounts.length !== 0) {
      changeName(accounts[0].name);
      changeEmail(accounts[0].username);
      updateRoles({username: accounts[0].username})
      //localStorage.setItem('username', accounts[0].username);
      updateRoles({name: accounts[0].name})
      //localStorage.setItem('name', accounts[0].name);
      AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchRole?email=${accounts[0].username}`)
        .then((response) => {

          if (response?.data?.recordset?.length > 0) {
            setRoleAssigned(response?.data?.recordset[0]?.roleName);
            updateRoles({role: response?.data?.recordset[0]?.roleName})
            //localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
          }
          else {
            setRoleAssigned('Employee');
            updateRoles({role: 'Employee'})
            //localStorage.setItem('role', 'Employee');
          }
        })

    } else {
      changeName('');
      changeEmail('');
    }
  }, [accounts]);


  useEffect(() => {
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchRole?email=${(roles.username)}`).then((response) => {
      if (response?.data?.recordset?.length > 0) {
        setRoleAssigned(response?.data?.recordset[0]?.roleName);
        updateRoles({role: response?.data?.recordset[0]?.roleName})
        //localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
      } else {
        setRoleAssigned('Employee');
        updateRoles({role: 'Employee' })
        //localStorage.setItem('role', 'Employee');
      }
    });
  }, []);
  useEffect(() => {
    fetchNotiData();
  }, []);
  // useEffect(() => {
  //   AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/notifications/cqnotifications')
  //     .then(function (response) {
  //       // handle success
  //       console.log(response.data);
  //       setData(response.data);
  //       console.log('This is data 1');
  //       console.log(response.data);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }, []);
  function handleChange(e) {
    setsearchparam(e.target.value);
    console.log(e.target.value);
  }
  function handleChange2(e) {
    setsearchval(e.target.value);
    console.log(e.target.value);
  }
  useEffect(() => {
    if (accounts.length !== 0) {
      changeName(accounts[0].name);
      changeEmail(accounts[0].username);
      updateRoles({username:  accounts[0].username})
      //localStorage.setItem('username', accounts[0].username);
      updateRoles({name: accounts[0].name})
      //localStorage.setItem('name', accounts[0].name);
      AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchRole?email=${accounts[0].username}`).then((response) => {
        if (response?.data?.recordset?.length > 0) {
          setRoleAssigned(response?.data?.recordset[0]?.roleName);
          updateRoles({role: response?.data?.recordset[0]?.roleName})
          //localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
        } else {
          setRoleAssigned('Employee');
          updateRoles({role: 'Employee'})
          //localStorage.setItem('role', 'Employee');
        }
      });
    } else {
      changeName('');
      changeEmail('');
    }
  }, [accounts]);

  useEffect(() => {
    if (accounts.length > 0) {
      console.log('starting below');
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          console.log(response);
          dataService
            .getMSGraphPhoto(response.accessToken)
            .then((image) => {
              if (image.type === 'image/jpeg') setProfilePhoto(image);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  }, [accounts]);
  return (
    <>
      <div className='d-flex justify-content-between align-items-center px-5 py-2' style={{ backgroundColor: "black" }}>

        {/* navbar starts here */}
        <CommonNavbar
          images={[Logo, INT_Logo]}
          avatar={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
          appVersion={process.env.REACT_APP_VERSION || "PROD 5.1"}
          username={(roles.name)?.length > 0 ? (roles.name) : "Hi, Guest"}
          useremail={(roles.username)?.length > 0 ? (roles.username) : null}
        />
      </div>

      {/* //navbar ends here  */}


      <div className="d-flex justify-content-around align-items-center" id="navbar_header">

        <div className="d-flex justify-content-evenly align-items-center">
          <NavLink to="/cq-portal-home" className={({ isActive }) => `${isActive ? "navlink_active navlink" : "navlink"}`}>
            <FaHome style={{ height: "1.5rem", color: "white" }} />
            Home
          </NavLink>

          {(roles.role) !== 'ABI_CQ_SPOC' && (roles.role) !== 'Manager' &&
            <NavLink to="/add-complaint" className={({ isActive }) => `${isActive ? "navlink_active navlink" : "navlink"}`}>
              <AiFillFileAdd style={{ height: '1.5rem', color: "white" }} />
              Add Complaint
            </NavLink>
          }
          <NavLink to="/complaints" className={({ isActive }) => `${isActive ? "navlink_active navlink" : "navlink"}`}>
            <IoDocumentsSharp style={{ height: '1.5rem', color: "white" }} />
            All Complaints
          </NavLink>

          {(roles.role) === 'ABI_CQTeam' &&
            <NavLink to="/register" className={({ isActive }) => `${isActive ? "navlink_active navlink" : "navlink"}`}>
              <AiFillFileAdd style={{ height: '1.5rem', color: "white" }} />
              Register
            </NavLink>
          }
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <FormControl
            placeholder="Search"
            className="" style={{ width: "200px" }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                var searchurl = `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/search/${searchparam}`;
                AxiosApi.post(searchurl, { searchvalue: searchval, searchpar: searchparam }).then(
                  (response) => {
                    var searchres = '';
                    console.log(response.data);
                    searchres = response.data;
                    if (searchres == 'No Result') {
                      alert("Your requested search couldn't be found.");
                    } else {
                      if (
                        (roles.role) == 'External_Customer' ||
                        (roles.role) == 'Internal_Customer'
                      ) {
                        const surl = '/complaint-2/num/' + searchres;
                        window.location.href = surl;
                      } else {
                        const surl = '/complaint/num/' + searchres;
                        window.location.href = surl;
                      }
                    }
                  },
                );
              }
            }}
            onChange={handleChange2}
          />

          <select
            onChange={handleChange}
            className="form-select form-select-sm select-size mx-2"
            aria-label=".form-select-sm example"
          >
            <option value="Acknowledgement No." selected>
              Acknowledgement No.
            </option>
            <option value="Complaint No.">Complaint No.</option>
            <option value="Sales Order">Sales Order</option>
            <option value="Customer PO">Customer PO</option>
            <option value="Container No.">Container No.</option>
            <option value="SKU">SKU</option>
            <option value="Booking No.">Booking No.</option>
            <option value="Customer Name">Customer Name</option>
          </select>
        </div>

        <Notification
          data={data}
          showModal={showModal}
          handleModalToggle={handleModalToggle}
          setUnreadMessagesCount={setUnreadMessagesCount}
        />

        {(roles.role) === 'ABI_CQTeam' ? (
          <div>
            {
              notificationLoader ? <Loader color="green" type="bars" /> :

                <Button
                  onClick={handleModalToggle}
                  color="black"
                  style={{ position: 'relative' }}
                >
                  <IconBell size={25} />
                  <span style={{
                    position: 'absolute',
                    color: "red",
                    // background: "yellow",
                    fontSize: "15px",
                    top: 0,
                    right: '5px',
                    minWidth: '25px',
                    padding: 'auto',
                    // borderRadius:"5px"
                    fontWeight: "bold"
                  }}>
                    {unreadMessagesCount}
                  </span>

                </Button>
            }
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NavBar;
