import React, { Component } from "react";
//import AxiosApi from "AxiosApi";
import { ToastContainer, toast } from "react-toastify";
// import download from "downloadjs";
import "../uploader.css";
import "../uploader2.css";
import AxiosApi from '../../../Api'

export class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.fileDownloaddHandler = this.fileDownloaddHandler.bind(this);
    this.state = {
      selectedFile: null,
      resFileName: "",
      showUploadButton: "fresh-button hide_data",
      showDownloadButton: "fresh-button",
      requiredData: this.props.requiredData,
    };
  }

  onChangeHandler = (event) => {
    var file = event.target.files;
    this.props.handleFileUpload(file);

    console.log(event.target.files);


    if (this.validateSize(event)) {
      console.log(file);
      // if return true allow to setState
      this.setState({
        selectedFile: file,
      });
    }
  };

  fileDownloaddHandler = () => {
    var data = this.state;
    console.log(data);
    AxiosApi
      .request({
        url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/upload/res",
        method: "Post",
        data,
        responseType: "arraybuffer",
        // headers: {
        //   "Content-Disposition": "attachment; filename=template.xlsx",
        //   "Content-Type":
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // },
      })

      .then((response) => {
        console.log(response);
        //////////////////new code
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });

    this.setState({
      resFileName: "",
    });
    this.setState({
      showDownloadButton: "fresh-button",
      showUploadButton: "fresh-button hide_data"
    });
  };


  fileUploadHandler = () => {

    const data = new FormData();

    for (var x = 0; x < this.state.selectedFile.length; x++) {
      data.append("file", this.state.selectedFile[x]);
    }

    console.log(this.state.selectedFile.length);
    console.log('seleted', this.state.selectedFile);
    const temp = Array.from(this.state.selectedFile);
    const a = temp.map(x => x.name);

    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/upload", data).then((res) => {

      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/sharepoint", { name: a, requiredData: this.state.requiredData }).then((res) => {
        // then print response status
        console.log(res);
        this.setState({ resFileName: res.data });

        this.setState({
          showUploadButton: "fresh-button",
          showDownloadButton: "fresh-button hide_data"
        });

      }).catch(err => {
        toast.warning("Failed to upload files");
      });

    })
      .catch(err => {
        toast.warning("Failed to upload files", err);
      })
  };


  validateSize = (event) => {
    let file = event.target.files;
    let size = 300000000;
    let err = "";
    // console.log(file.size);
    for (var x = 0; x < file.length; x++) {
      if (file[x].size > size) {
        err = file[x].type + "is too large, please pick a smaller file\n";
        toast.error(err);
      }
    }
    if (err !== "") {
      event.target.value = null;
      console.log(err);
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-1"></div>
              <div className="col-md-1"></div>
              <div className="col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group files">
                    <label>
                      {" "}
                      <h4>Upload Your File</h4>{" "}
                    </label>
                    <input
                      type="file"
                      name="file"
                      multiple
                      className="form-control"
                      onChange={this.onChangeHandler}
                    />
                  </div>

                  <div className="col-md-12 pull-right">
                    <button
                      width="100%"
                      type="button"
                      // className="fresh-button hide_data"
                      className={this.state.showDownloadButton}
                      onClick={this.fileUploadHandler}
                    >
                      Upload File
                    </button>
                    <button
                      width="100%"
                      type="button"
                      className={this.state.showUploadButton}
                      onClick={this.props.closePopup}
                    >
                      Return to Customer Portal
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-sm-2">
                <ToastContainer />
              </div>
              <div className="col-md-1">
                {" "}
                <button
                  width="100%"
                  type="button"
                  className="btn btn-danger"
                  onClick={this.props.closePopup}
                >
                  X
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
