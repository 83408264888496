import React, { Component } from "react";
import "../style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosApi from "../../../Api";
import Moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import LoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Comparator } from 'react-bootstrap-table2-filter';
import { roles } from "../../../Constants";

function columns(fileDownloaddHandler, retryHandler) {
	return [
		{
			dataField: "File_name",
			text: "File Name",
			formatter: (cell, row) => {
				if (row.Upload_Status === 'Completed' || row.Upload_Status === 'Failed' || row.Upload_Status === 'Partially Completed') {
					return (<span className="dllink" onClick={() => fileDownloaddHandler(cell, row.id)}>{cell}</span>);
				}
				else {
					return (
						<span>{cell}</span>
					);
				}
			}
		},
		{
			dataField: "Max_Rows",
			text: "# Records",
			editable: false,
			style: {
				'width': '100px'
			}
		},
		{
			dataField: "Completed_Rows",
			text: "# Success",
			editable: false,
			style: {
				'width': '100px'
			}
		},
		{
			dataField: "Error_Rows",
			text: "# Errors",
			editable: false,
			style: {
				'width': '100px'
			}
		},
		{
			text: "Progress",
			dataField: "Percentage",
			editable: false,
			style: {
				'width': '100px'
			},
			formatter: (cell, row) => {
				if (!isNaN(row.Completed_Rows) && !isNaN(row.Error_Rows) && !isNaN(row.Max_Rows)) {
					let percent = Math.round((((row.Completed_Rows + row.Error_Rows) / row.Max_Rows) * 100));
					if (isNaN(percent)) {

					}
					else {
						return (
							<span>{percent}%</span>
						);
					}
				}
				else {
					return (
						<span></span>
					);
				}
			}
		},
		{
			dataField: "Upload_Status",
			text: "Status",
			editable: false
		},
		{
			dataField: "start_time",
			text: "Start Time - End Time",
			editable: false,
			formatter: (cell, row) => {
				return (
					<span>{Moment(row.start_time).format('DD-MM-YYYY HH:mm:ss')} to {Moment(row.end_time).format('DD-MM-YYYY HH:mm:ss')}</span>
				);
			}
		},
		{
			dataField: "actions",
			text: "Actions",
			editable: false,
			formatter: (cellx, row) => {
				//let html = "";
				if (row.Upload_Status === 'Completed' || row.Upload_Status === 'Failed') {
					//html += `<button className="btn-icon fresh-button" type="button" title="Download Response"><i className="fa fa-download"></i></button>`;
				}
				if (row.Upload_Status === 'Failed') {
					//html += `<button className="btn-icon fresh-button" type="button" title="Retry"><i className="fa fa-download"></i></button>`;
				}
				//return (html);
				let cell = row.File_name;
				if (row.Upload_Status === 'Failed' || row.Upload_Status === 'Partially Completed' || row.Upload_Status === 'Timeout') {
					return (<button className="btn-icon fresh-button" type="button" onClick={() => retryHandler(cell, row.id, row)} title="Retry"><i className="lni lni-spinner-arrow"></i></button>);
				}
				if (row.Upload_Status === 'Completed' || row.Upload_Status === 'Failed') {
					return (<button className="btn-icon fresh-button" type="button" onClick={() => fileDownloaddHandler(cell, row.id)} title="Download Response"><i className="lni lni-download"></i></button>);
				}
			}
		},
	]
};

function columnsRecord() {
	return [
		{
			dataField: "row_no",
			text: "Serial #",
			style: {
				'width': '100px'
			},
			formatter: (cell, row) => {
				return (
					<span>{row.row_no}</span>
				);
			}
		},
		{
			dataField: "request_id",
			text: "Request ID",
			style: {
				'width': '150px'
			},
			formatter: (cell, row) => {
				return (
					<span>{row.request_id}</span>
				);
			}
		},
		{
			dataField: "request_type",
			text: "Request Type",
			style: {
				'width': '150px'
			}
		},
		{
			dataField: "sales_order",
			text: "Sales Order",
			style: {
				'width': '150px'
			}
		},
		{
			dataField: "status",
			text: "Status",
			editable: false,
		}
	]
};

function indication() {
	return <div style={{ color: 'grey' }}>No Data to Show</div>
}

const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize, indication, fileDownloaddHandlerX, rowEvents, retryHandler }) => (
	<div>
		<BootstrapTable
			remote
			keyField="id"
			data={data}
			columns={columns(fileDownloaddHandlerX, retryHandler)}
			noDataIndication={indication}
			rowEvents={rowEvents}
			headerClasses="cust_header-class"
			classes="batches-table"
			striped
			hover
			condensed
			//defaultSorted={ defaultSorted }
			//filter={ filterFactory() }
			pagination={paginationFactory({ page, sizePerPage, totalSize })}
			//cellEdit={ cellEditFactory(cellEditProps) }
			onTableChange={onTableChange}
		/>
	</div>
);

const RemoteAllRecords = ({ data, page, sizePerPage, onTableChange, totalSize, indication }) => (
	<div>
		<BootstrapTable
			remote
			keyField="id"
			data={data}
			columns={columnsRecord()}
			noDataIndication={indication}
			headerClasses="cust_header-class"
			classes="batch-data-table"
			striped
			hover
			condensed
			pagination={paginationFactory({ page, sizePerPage, totalSize })}
			onTableChange={onTableChange}
		/>
	</div>
);

export class UploadPage extends Component {

	batches = [];
	batchData = [];
	selectedBatch = null;

	interval;
	constructor(props) {
		super(props);
		//this.fileDownloaddHandler = this.fileDownloaddHandler.bind(this);
		this.lastId = 0;

		this.state = {
			showPopup: false,
			showNewShippingPoint: "fresh-field-wrapper hide_data",
			showOldValue: "fresh-field-wrapper hide_data",
			showNewValue: "fresh-field-wrapper hide_data",
			showPartnerFunction: "fresh-field-wrapper hide_data",
			showTextTypes: "fresh-field-wrapper hide_data",
			showTextActionTypes: "fresh-field-wrapper hide_data",
			showMADswitch: "fresh-field-wrapper hide_data",
			showNewDeliveryBlk: "fresh-field-wrapper hide_data",
			showQtyTable: "table-mini hide_data",
			showUploadButton: "fresh-button",
			showDownloadButton: "fresh-button hide_data",
			newValueFieldType: "text",
			newValueReadOnly: false,
			request_zone: "",
			request_type: "",
			requestTypeList: "",
			requestorTypeList: "",
			requestReasonList: "",
			zone_label: "",
			textList: "",
			partner_list: "",
			truck_trailer_list: "",
			request_flow: "3",
			requester_type: "",
			new_shipping_point: "",
			old_value: "",
			new_value: "",
			partner_name: "",
			text_type: "",
			requester_email: this.props.email,
			sales_order: "",
			quotation: "",
			request_reason: "",
			plant_mapping: "",
			sales_org: "",
			shipping_point_mapping: "",
			buycoList: "",
			selectedOption_textAction: "",
			selectedOption_MADswitch: "woMAD",
			portDoorIndicatorList: "",
			new_delivery_blk: "Z2",
			po_date: null,
			customer_text: "",
			additional_Information: "",
			additional_notification_to: "",
			filesData: [],
			selectedOption_sendZACK: "false",
			plant: "",
			quantity: "",
			po_num: "",
			showRejTable: false,
			rejectItems: [],
			fillInItem: "",
			nonSelectable: [],
			showRepricing: false,
			showCorrectTransfer: false,
			correctTransfer: "",
			loading: false,
			loadingText: "",
			showSwitchTable: false,
			showAdditionTable: false,
			addItemCount: 1,
			selectedFile: null,
			resFileName: "",
			email: this.props.email,
			exitFlag: false,
			loadPercentage: 0,
			page: 1,
			data: [],
			totalSize: 0,
			sizePerPage: 10,
			batchPage: 1,
			batchData: [],
			batchTotalSize: 0,
			batchSizePerPage: 10,
			selectedBatch: null
		};

		this.handleTableChange = this.handleTableChange.bind(this);
		this.handleTableChangeRecords = this.handleTableChangeRecords.bind(this);
	}

	componentDidMount() {
		this.syncBatches();
		this.interval = setInterval(() => {
			this.syncBatches();
		}, 30000);
	};

	componentWillUnmount() {
		clearInterval(this.interval);
	};

	handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
		const currentIndex = (page - 1) * sizePerPage;
		console.log('Changing...');
		setTimeout(() => {
			// Handle cell editing
			if (type === 'cellEdit') {
				const { rowId, dataField, newValue } = cellEdit;
				this.batches = this.batches.map((row) => {
					if (row.id === rowId) {
						const newRow = { ...row };
						newRow[dataField] = newValue;
						return newRow;
					}
					return row;
				});
			}

			let result = this.batches;

			// Handle column filters
			result = result.filter((row) => {
				let valid = true;
				for (const dataField in filters) {
					const { filterVal, filterType, comparator } = filters[dataField];

					if (filterType === 'TEXT') {
						if (comparator === Comparator.LIKE) {
							valid = row[dataField].toString().indexOf(filterVal) > -1;
						} else {
							valid = row[dataField] === filterVal;
						}
					}
					if (!valid) break;
				}
				return valid;
			});
			// Handle column sort
			if (sortOrder === 'asc') {
				result = result.sort((a, b) => {
					if (a[sortField] > b[sortField]) {
						return 1;
					} else if (b[sortField] > a[sortField]) {
						return -1;
					}
					return 0;
				});
			}
			else {
				result = result.sort((a, b) => {
					if (a[sortField] > b[sortField]) {
						return -1;
					} else if (b[sortField] > a[sortField]) {
						return 1;
					}
					return 0;
				});
			}

			this.setState(() => ({
				page,
				data: result.slice(currentIndex, currentIndex + sizePerPage),
				totalSize: result.length,
				sizePerPage
			}));
		}, 500);
	}

	handleTableChangeRecords = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
		const currentIndex = (page - 1) * sizePerPage;
		console.log('Changing...');
		setTimeout(() => {
			// Handle cell editing
			if (type === 'cellEdit') {
				const { rowId, dataField, newValue } = cellEdit;
				this.batches = this.batches.map((row) => {
					if (row.id === rowId) {
						const newRow = { ...row };
						newRow[dataField] = newValue;
						return newRow;
					}
					return row;
				});
			}

			let result = this.batchData;

			// Handle column filters
			result = result.filter((row) => {
				let valid = true;
				for (const dataField in filters) {
					const { filterVal, filterType, comparator } = filters[dataField];

					if (filterType === 'TEXT') {
						if (comparator === Comparator.LIKE) {
							valid = row[dataField].toString().indexOf(filterVal) > -1;
						} else {
							valid = row[dataField] === filterVal;
						}
					}
					if (!valid) break;
				}
				return valid;
			});
			// Handle column sort
			if (sortOrder === 'asc') {
				result = result.sort((a, b) => {
					if (a[sortField] > b[sortField]) {
						return 1;
					} else if (b[sortField] > a[sortField]) {
						return -1;
					}
					return 0;
				});
			}
			else {
				result = result.sort((a, b) => {
					if (a[sortField] > b[sortField]) {
						return -1;
					} else if (b[sortField] > a[sortField]) {
						return 1;
					}
					return 0;
				});
			}

			this.setState(() => ({
				batchPage: page,
				batchData: result.slice(currentIndex, currentIndex + sizePerPage),
				batchTotalSize: result.length,
				batchSizePerPage: sizePerPage
			}));
		}, 500);
	}

	onChangeHandler = (event) => {
		var file = event.target.files;
		if (this.validateSize(event)) {
			this.setState({
				selectedFile: file,
			});
		}
	};

	fileDownloaddHandler = (filename, batch_id) => {
		console.log('Downloading file ', filename, '...', batch_id);
		var data = {
			resFileName: filename,
			email: this.state.email,
			batch_id: batch_id
		};
		let newfilename = filename.replace('.xlsx', '-response.xlsx');
		AxiosApi.request({
			url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/upload/res2",
			method: "Post",
			data,
			responseType: "arraybuffer",
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", newfilename);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			});
		this.setState({
			resFileName: "",
			showDownloadButton: "fresh-button hide_data",
			showUploadButton: "fresh-button",
		});
	};

	fileUploadHandler = (call) => {
		const data = new FormData();
		if (this.state.selectedFile === null) {
			const err = "No Files selected";
			toast.error(err);
		} else {
			for (var x = 0; x < this.state.selectedFile.length; x++) {
				data.append("file", this.state.selectedFile[x]);
			}
			//showUploadButton: "fresh-button hide_data",
			this.setState({
				loading: true,
			})
			AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/${call}`, data, {
				headers: { email: this.state.email },
			})
				.then((res) => {
					// then print response status
					console.log("Response Received");
					toast.info(
						"File has been uploaded, please wait for a few seconds to see the progress."
						, { toastId: 'uploading' });
					this.setState({
						resFileName: res.data,
						loading: false,
						page: 1
					});
					this.syncBatches();
					setTimeout(() => {
						document.getElementById('uploadField').value = null;
						this.state.selectedFile = null;
					}, 500);
				})
				.catch((error) => {
					console.error("There was an error in uploading data !", error);
					toast.error("Error in uploading, please try again");
				});
		}
	};

	validateSize = (event) => {
		let file = event.target.files;
		let size = 300000000;
		let err = "";

		if (file.length === 0) {
			err = "No Files selected";
			toast.error(err);
		} else {
			for (var x = 0; x < file.length; x++) {
				if (file[x].size > size) {
					err = file[x].type + "is too large, please pick a smaller file\n";
					toast.error(err);
				}
			}
		}
		if (err !== "") {
			event.target.value = null;
			console.log(err);
			return false;
		}
		return true;
	};

	togglePopup = () => {
		this.setState({
			showPopup: !this.state.showPopup,
		});
	};

	downloadExcelFile = (fileName) => {
		AxiosApi({
			url: `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/exportTemp/`, // your url
			method: "POST",
			responseType: "blob", // important
			data: {
				file: fileName,
			}
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				if (fileName === '/template.xlsx') {
					link.setAttribute("download", "mass_upload_template.xlsx"); // or any other extension
				}
				else {
					link.setAttribute("download", "sku_mass_upload_template.xlsx");
				}
				document.body.appendChild(link);
				link.click();
				toast.success("Template downloaded successfully.");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	updateFilesData = (data) => {
		this.setState({
			filesData: data,
		});
	};

	retryHandler = (cell, batch_id, batch) => {
		var data = {
			batch_id: batch_id,
			batch_type: batch.upload_type
		};
		AxiosApi.request({
			url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/upload/retry",
			method: "post",
			data
		})
			.then((response) => {
				setTimeout(() => {
					this.syncBatches();
				}, 2000);
			});
	};


	rowEventsC = () => {
	};

	rowEvents = {
		onClick: (e, row, rowIndex) => {
			this.selectedBatch = row;
			this.setState({
				selectedBatch: row,
			});
			this.loadBatchDetails(row);
		},
		onMouseEnter: (e, row, rowIndex) => {
			//console.log(`enter on row with index: ${rowIndex}`);
		}
	};

	loadBatchDetails = (row) => {
		console.log(row);
		this.batchData = [];
		AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/batches/records", { params: { id: row.id, page: this.state.batchPage, pagesize: this.state.batchSizePerPage } })
			.then((response) => {
				if (response.data.status) {
					this.batchData = response.data.rows;
					let offset = 0 * this.state.batchSizePerPage;
					let totalSize = response.data.rows.length;
					this.setState({
						batchData: response.data.rows.slice(offset, offset + this.state.batchSizePerPage),
						batchTotalSize: totalSize,
						batchPage: 1
					});
				}
			})
			.catch((error) => {
				console.error(
					"There was an error in fetching batch data.",
					error
				);
			});
	};

	syncBatches = () => {
		//console.log(this.state.page,this.state.sizePerPage);
		AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/batches", { params: { last_id: this.lastId, page: this.state.page, pagesize: this.state.sizePerPage }
		 ,headers: {
			
			user:(roles.username)
		
		  }})
			.then((response) => {
				if (response.data.status) {
					this.batches = response.data.rows;
					let offset = (this.state.page - 1) * this.state.sizePerPage;
					let totalSize = response.data.rows.length;
					this.setState({
						data: response.data.rows.slice(offset, offset + this.state.sizePerPage),
						totalSize: totalSize
					});
				}
				// if (response.data.rows[0].Upload_Status === "Completed") {
				//   toast.success(
				// 	"File processed successfully! Please download the Response."
				//   ,{autoClose: 5000});
				//   toast.dismiss('uploading');
				//   this.setState({
				// 	loading: false,
				// 	showDownloadButton: "fresh-button",
				//   });
				// } else {
				// 	var percentage =
				// 	(response.data[0].Completed_Rows / response.data[0].Max_Rows) *
				// 	100;
				// 	percentage = Math.round(percentage);
				// 	this.setState({
				// 	loadPercentage: percentage,
				// 	});
				// }
			})
			.catch((error) => {
				console.error(
					"There was an error in fetching upload status data !",
					error
				);
			});
	}

	onCancel = () => {
		window.location.reload(true);
	};

	onChangeCorrectTransfer = (e) => {
		if (e.target.value === "") {
			this.setState({ new_value: "Transfer" });
		} else {
			const a = e.target.value.trim();

			this.setState({
				new_value: `Transfer-${a}`,
			});
		}
	};

	render() {
		const Filler = (props) => {
			return (
				<div className="filler" style={{ width: `${props.percentage}%` }} />
			);
		};
		const ProgressBar = (props) => {
			return (
				<div className="progress-bar-1">
					<Filler percentage={props.percentage} />
				</div>
			);
		};

		const { data, sizePerPage, page, batchData, batchSizePerPage, batchPage } = this.state;

		return (
			<LoadingOverlay
				active={this.state.loading}
				spinner
				text={this.state.loadingText}
			>
				<div className="container">

					<div className="d-flex justify-content-between align-items-center">
						<button
							type="button"

							className="fresh-button cust_btn-cls"
							onClick={() => this.downloadExcelFile('/template.xlsx')}
						>
							Download Template
						</button>
						<button

							type="button"
							className="fresh-button cust_btn-cls "
							onClick={() => this.downloadExcelFile('/template_sku.xlsx')}
						>
							Download SKU Template
						</button>
					</div>

					<div className="fresh-form">
						<div className="form-header">
							Upload File
						</div>
						{/* <h5 className="reminder">* Mandatory fields</h5> */}
						<div className="">
							<div className="container">
								<div style={{ justifyContent: 'space-between', margin: '15px' }} className="row">
									<div className="col-md-1"></div>
									<div className="col-md-8 text-center">
										<form method="post" action="#" id="#">
											<div className="form-group files">
												<input
													type="file"
													name="file"
													id="uploadField"
													multiple
													className="form-control"
													onChange={this.onChangeHandler}
												/>
											</div>
											<div className="col-md-12 pull-right">
												<button
													type="button"
													// className="fresh-button hide_data"
													className={this.state.showUploadButton}
													onClick={() => this.fileUploadHandler('upload')}
												>
													Upload File
												</button>

												<button
													type="button"
													// className="fresh-button hide_data"
													className={this.state.showUploadButton}
													onClick={() => this.fileUploadHandler('skufilterData')}
												>
													Upload SKU File
												</button>

												{this.state.loading ? (
													<ClipLoader loading={this.state.loading} />
												) : (
													<button
														width="100%"
														type="button"
														className={this.state.showDownloadButton}
														onClick={this.fileDownloaddHandler}
													>
														Download Response
													</button>
												)}
												{this.state.loading && (
													<ProgressBar percentage={this.state.loadPercentage} />
												)}
											</div>
										</form>
									</div>
									<div className="col-md-1"></div>
								</div>
							</div>
						</div>
					</div>

					<div className="fresh-form">
						<div className="form-header">
							Batches
						</div>
						<div className="">
							<div className="fresh-field-wrapperx">
								<RemoteAll
									data={data}
									page={page}
									sizePerPage={sizePerPage}
									indication={indication}
									totalSize={this.state.totalSize}
									onTableChange={this.handleTableChange}
									fileDownloaddHandlerX={this.fileDownloaddHandler}
									rowEvents={this.rowEvents}
									retryHandler={this.retryHandler}
								/>
								{/* <BootstrapTable
									bootstrap4
									remote
									noDataIndication={indication}
									keyField="id"
									data={this.batches}
									columns={columns}
									headerClasses="cust_header-class"
								>
								</BootstrapTable> */}
							</div>
						</div>
					</div>

					{this.batchData.length > 0 &&
						<div className="fresh-form">
							<div className="form-header">
								Batch Records - {this.state.selectedBatch?.File_name}
							</div>
							<div className="">
								<div className="fresh-field-wrapperx">
									<RemoteAllRecords
										data={batchData}
										page={batchPage}
										sizePerPage={batchSizePerPage}
										indication={indication}
										totalSize={this.state.batchTotalSize}
										onTableChange={this.handleTableChangeRecords}
									/>
								</div>
							</div>
						</div>
					}
					<ToastContainer
						position="top-right"
						autoClose={false}
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
					/>
				</div>
			</LoadingOverlay>
		);
	}
}

export default UploadPage;
